// import React, { Component } from 'react';
// import { acceptOrders } from '../../Actions/orderActions';
// import { connect } from 'react-redux';
// import './AcknowledgeOrders.css'
// import { RouteComponentProps } from 'react-router-dom';

// type AcknowledgeProps = {
//   acceptOrders: (type: string, id: string | number, token?: string) => void
// } & RouteComponentProps<{ token: string; type: string; cutoff?: string }>
// class Acknowledge extends Component<AcknowledgeProps, {}> {

//   componentDidMount() {
//     const { type, token } = this.props.match.params;
//     this.props.acceptOrders(type, undefined ?? '', token);
//   }
//   render() {
//     return (
//       <div className="card card-inverse-info main-acknowledge col-md-8 " id="context-menu-simple">
//         <div className="card-body">
//           <p className="card-text">
//             {this.props.match.params.type === 'ack_schedule' ? ' Thank you for acknowledging this schedule. We will send you the orders at around ' + atob(this.props.match.params.cutoff ?? '') : ''}
//             {this.props.match.params.type === 'accept_orders' ? <span className="accept-message">  Thank you for accepting these orders.</span> : ''}
//             {this.props.match.params.type === 'accept_changes' ? <span className="accept-message">    Thank you for accepting the changed orders. </span> : ''}
//           </p>
//         </div>
//       </div>
//     );
//   }
// }

// export default connect(null, { acceptOrders })(Acknowledge);


import React, { useEffect } from 'react';
import './AcknowledgeOrders.css';
import { useParams } from 'react-router-dom'; // React Router v6
import { useDispatch } from 'react-redux';
import { acceptOrders } from '../../Actions/orderActions';

const Acknowledge: React.FC = () => {
  const dispatch = useDispatch<any>();
  // Destructure the parameters from the URL using useParams (React Router v6)
  const { type, token, cutoff } = useParams<{ token: string; type: string; cutoff?: string }>();
  useEffect(() => {
    type && dispatch(acceptOrders(type, '', token));
  }, [dispatch, type, token]);

  return (
    <div className="card card-inverse-info main-acknowledge col-md-8 " id="context-menu-simple">
      <div className="card-body">
        <p className="card-text">
          {type === 'ack_schedule'
            ? ' Thank you for acknowledging this schedule. We will send you the orders at around ' + atob(cutoff ?? '')
            : ''}
          {type === 'accept_orders' ? <span className="accept-message">  Thank you for accepting these orders.</span> : ''}
          {type === 'accept_changes' ? <span className="accept-message">    Thank you for accepting the changed orders. </span> : ''}
        </p>
      </div>
    </div>
  );
};

export default Acknowledge;
// import React, { Component, createRef, RefObject } from 'react';
// import queryString from 'query-string';
// import { connect } from 'react-redux';
// import { withRouter, RouteComponentProps } from 'react-router-dom';
// import { getSingleOrderBilling } from '../../Actions/billingActions';
// import moment from 'moment';
// import { billingTranslations } from '../../Translations/BillingTranslations'
// import Breadcrumbs from '../../Common/Breadcrumbs';
// import TableHeader from '../../Common/TableHeader';
// import isEmpty from '../../Utilities/isEmpty';
// import ReactToPrint from 'react-to-print';
// import { InvoicesDetailsSkeleton } from '../Invoices/InvoicesDeatailsSkeleton';
// import { BillingPaymentStatus } from './Billing';
// import { ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';
// import { GetSingleOrderBilling } from '../../Interfaces/PayloadsAndResponses/Billing';
// import { WithTranslation } from 'react-i18next';
// import { Params } from '../Dashboard/Dashboard';

// type BillingDetailsProps = {
//   billing_loading: boolean;
//   i18n: WithTranslation | null;
//   single_order_billing_details: GetSingleOrderBilling | null;
//   getSingleOrderBilling: (id: string | number, restaurantId: string | number, queryParams?: string) => void;
// } & RouteComponentProps<Params>;

// type BillingDetailsState = {
//   sortBy: string;
//   sortType: string;
// }

// class BillingDetails extends Component<BillingDetailsProps, BillingDetailsState> {

//   printContent = createRef();
//   state: BillingDetailsState = {
//     sortBy: '',
//     sortType: ''
//   }

//   componentDidMount() {
//     billingTranslations();
//     const { id, restaurantId } = this.props.match.params || {};
//     if (id && restaurantId) {
//       this.props.getSingleOrderBilling(id, restaurantId);
//     }
//   }

//   handleSorting = (sortBy: string) => {
//     let sortType = this.state.sortType === 'ASC' ? 'DESC' : 'ASC';
//     this.props.single_order_billing_details?.billing?.orders?.sort((a, b) => {
//       if (sortType === 'ASC') {
//         if (sortBy === 'delivery_time') {
//           let dateA = new Date(a[sortBy]).getTime(), dateB = new Date(b[sortBy]).getTime();
//           return dateA - dateB;
//         } else {
//           //@ts-ignore
//           return parseFloat(a[sortBy]) - parseFloat(b[sortBy])
//         }
//       } else {
//         if (sortBy === 'delivery_time') {
//           let dateA = new Date(a[sortBy]).getTime(), dateB = new Date(b[sortBy]).getTime();
//           return dateB - dateA;
//         } else {
//           //@ts-ignore
//           return parseFloat(b[sortBy]) - parseFloat(a[sortBy])
//         }
//       }
//     });

//     this.setState({ sortBy, sortType });
//   }

//   componentDidUpdate(prevProps: BillingDetailsProps) {
//     const { id, restaurantId } = this.props.match.params || {};
//     if (prevProps.location.search !== this.props.location.search && id && restaurantId) {
//       this.props.getSingleOrderBilling(id, restaurantId, this.props.location.search);
//     }
//   }

//   generateQueryParams = () => {
//     let parsedValue: { sort_by?: string, sort_type?: string } = {};
//     const { sortBy, sortType } = this.state;
//     if (sortBy) parsedValue.sort_by = sortBy;
//     if (sortType) parsedValue.sort_type = sortType;

//     let queryURL = !isEmpty(parsedValue) ? `?${queryString.stringify(parsedValue, { skipNull: true, skipEmptyString: true })}` : '';

//     this.props.history.push(`${window.location.pathname}${queryURL}`);
//   }

//   getPrintContent = () => {
//     const printRefCopy = (this.printContent as unknown as HTMLElement)?.cloneNode(true) as HTMLElement;

//     printRefCopy.style.margin = 'auto';
//     printRefCopy.style.padding = '10px';
//     printRefCopy.style.width = '80%';
//     printRefCopy.style.marginTop = '50px';

//     let theFirstChild = printRefCopy?.firstChild;

//     let heading = document.createElement('h1');
//     heading.style.color = 'white';
//     heading.innerText = 'Bill Details';
//     heading.style.textAlign = 'center';
//     heading.style.marginBottom = '50px';
//     heading.style.background = '#ff5e5e';
//     heading.style.padding = '20px';

//     printRefCopy.insertBefore(heading, theFirstChild);

//     printRefCopy?.querySelectorAll('.bg-dark.text-white')
//       ?.forEach((th) => th?.classList?.remove('text-white'));
//     printRefCopy?.querySelectorAll('.btn-link.d-inline.text-decoration-none, .btn.btn-light.btn-sm.text-danger')
//       ?.forEach((link) => link?.remove());
//     return printRefCopy
//   }

//   goToBillingsHandler = () => {
//     const { state } = this.props.history.location || {};
//     const { restaurantId } = this.props.match.params || {};
//     if (state) {
//       this.props.history.push(`/dashboard/restaurant/${restaurantId}/billing${state}`)
//     } else {
//       this.props.history.push(`/dashboard/restaurant/${restaurantId}/billing`)
//     }
//   }
//   render() {
//     const {
//       billing_loading,
//       single_order_billing_details,
//     } = this.props

//     const {
//       adjustments,
//       orders,
//       orders_from,
//       orders_to,
//       commission_percentage,
//       sales_tax_percentage,
//       billing_number,
//       commission,
//       payment_status,
//       food_total,
//       total_items,
//       total_payout,
//     } = single_order_billing_details?.billing || {};

//     let total_order_total_items = 0;
//     let total_order_food_total = 0;
//     let total_order_commission = 0;
//     let total_sales_tax = 0;
//     let Orders = single_order_billing_details?.billing && (orders ?? []).map((order, index) => (
//       <tr key={index}>
//         <td className='pl-0'>
//           {order.delivery_time}
//           <span style={{ display: 'none' }}>
//             {total_order_total_items = total_order_total_items + order.items}
//             {total_order_food_total = total_order_food_total + Number(order.food_total)}
//             {total_order_commission = total_order_commission + Number(order.commission)}
//             {total_sales_tax = total_sales_tax + Number(order.sales_tax_total)}
//           </span>
//         </td>
//         <td>
//           {order.items}
//         </td>
//         <td>
//           ${order.food_total}
//         </td>
//         <td>
//           ${order.commission} ({Number(commission_percentage).toFixed(2)}%)
//         </td>
//         <td>
//           ${order.sales_tax_total} ({Number(sales_tax_percentage).toFixed(2)}%)
//         </td>
//       </tr>
//     ));

//     let totalAdjustment = 0;
//     let Adjustments = adjustments?.map((adjustment, index) => {
//       totalAdjustment = totalAdjustment + parseFloat(adjustment.price);
//       return <tr key={index}>
//         <td className='pl-0'>{adjustment?.adjustment_date}</td>
//         <td>{adjustment?.description}</td>
//         <td>-${adjustment?.price}</td>
//       </tr>
//     });


//     const columns = [
//       { label: 'Delivery Date', columnName: 'delivery_time', sortable: true },
//       { label: 'Total Items', columnName: 'items', sortable: true },
//       { label: 'Food Total', columnName: 'food_total', sortable: true },
//       { label: 'Commission', columnName: 'commission', sortable: true },
//       { label: 'Sales Tax', columnName: 'sales_tax_total' }
//     ];
//     const adjustmentColumns = [
//       { label: 'Date' },
//       { label: 'Description' },
//       { label: 'Amount' }
//     ];

//     const { state } = this.props.history.location || {};

//     const linkList = [
//       { name: 'Bills', url: `/dashboard/restaurant/${this.props.match.params.restaurantId}/billing${state || ''}`, show: true },
//       { name: `Bill # ${billing_number}`, show: true }
//     ];
//     return (
//       <div>
//         {single_order_billing_details?.billing && billing_loading === false ? (
//           <div>
//             <Breadcrumbs linkList={linkList} />
//             <h2 className='mt-4'>
//               <span className='text-secondary cursor-pointer'>
//                 <span onClick={this.goToBillingsHandler}>
//                   <i className='fas fa-arrow-circle-left' />
//                 </span>
//               </span> Bill Details
//             </h2>
//             <p className='muted mb-4'>
//               Please see full details of this bill below.
//             </p>
//             <div id='printContent' ref={ref => this.printContent = ref as unknown as RefObject<HTMLDivElement>}>
//               <div className='row mt-4 mb-2'>
//                 <div className='col-6 col-md-auto m-auto'>
//                   <h6 className='font-weight-bold'>No. {billing_number}</h6>
//                   <h6 className='text-muted font-weight-bold'>
//                     {BillingPaymentStatus[payment_status!] ?
//                       <label className={`text-capitalize badge badge-${BillingPaymentStatus[payment_status!]}`}>
//                         {payment_status}
//                       </label>
//                       : null
//                     }
//                   </h6>
//                 </div>

//                 <div className='col-6 col-md-auto m-auto'>
//                   <h6 className='font-weight-bold'>{total_items}</h6>
//                   <h6 className='text-muted font-weight-bold'>Items</h6>
//                 </div>

//                 <div className='col-6 col-md-auto m-auto'>
//                   <h6 className='font-weight-bold text-capitalize'>
//                     {moment(orders_from).format('MM/DD ')} -{' '}
//                     {moment(orders_to).format('MM/DD')}
//                   </h6>
//                   <h6 className='text-muted font-weight-bold'>
//                     Ordered Between
//                   </h6>
//                 </div>

//                 <div className='col-6 col-md-auto m-auto'>
//                   <h6 className='font-weight-bold text-capitalize'>${food_total}</h6>
//                   <h6 className='text-muted font-weight-bold'>Food Total</h6>
//                 </div>

//                 <div className='col-6 col-md-auto m-auto'>
//                   <h6 className='font-weight-bold text-capitalize'>${commission}</h6>
//                   <h6 className='text-muted font-weight-bold'>Commission</h6>
//                 </div>

//                 <div className='col-6 col-md-auto m-auto'>
//                   <h6 className='font-weight-bold text-capitalize'>${total_payout}</h6>
//                   <h6 className='text-muted font-weight-bold'>Payout</h6>
//                 </div>
//               </div>
//               <div className='table-responsive'>
//                 <table className='table'>
//                   <TableHeader
//                     columns={columns}
//                     sortingColumn={this.state.sortBy}
//                     handleSorting={this.handleSorting}
//                   />
//                   <tbody>
//                     {Orders}
//                     <tr>
//                       <td className='pl-0'>
//                         <b>{this.props.i18n && this.props.i18n.t('Total')}</b>
//                       </td>
//                       <td>
//                         <b> {total_order_total_items} </b>
//                       </td>
//                       <td>
//                         <b>${Number(total_order_food_total).toFixed(2)}</b>
//                       </td>
//                       <td>
//                         <b> ${Number(total_order_commission).toFixed(2)}</b>
//                       </td>
//                       <td>
//                         <b> ${Number(total_sales_tax).toFixed(2)}</b>
//                       </td>
//                     </tr>
//                   </tbody>
//                 </table>
//               </div>
//               {!isEmpty(adjustments) &&
//                 <div className='table-responsive'>
//                   {/* <h2 className='mb-3'>Adjustments</h2> */}
//                   <table className='table'>
//                     <TableHeader columns={adjustmentColumns} />
//                     <tbody>
//                       {Adjustments}
//                       <tr>
//                         <td />
//                         <td>
//                           <b>{this.props.i18n && this.props.i18n.t('Total')}</b>
//                         </td>
//                         <td>
//                           <b>-${totalAdjustment}</b>
//                         </td>
//                       </tr>
//                     </tbody>
//                   </table>
//                 </div>
//               }
//             </div>
//             <hr />
//             <div className='container-fluid w-100'>
//               <ReactToPrint
//                 trigger={() => {
//                   return (
//                     <button className='btn btn-primary float-right mt-4 ml-2 wide-btn'>
//                       <i className='mdi mdi-printer mr-1'></i>Print
//                     </button>
//                   );
//                 }}
//                 content={this.getPrintContent}
//                 documentTitle='Billing Details'
//               />
//             </div>
//           </div>
//         ) : (
//           <span>
//             <InvoicesDetailsSkeleton />
//           </span>
//         )}
//       </div>
//     );
//   }
// }

// const mapStateToProps = (state: ReduxRootState) => ({
//   single_order_billing_details: state.billing.single_order_billing_details,
//   billing_loading: state.billing.billing_loading,
//   i18n: state.i18n.i18n
// })

// export default connect(mapStateToProps, { getSingleOrderBilling })(withRouter(BillingDetails));

import React, { useEffect, useRef, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom'; // removed v5 useHistory
import { getSingleOrderBilling } from '../../Actions/billingActions';
import moment from 'moment';
import { billingTranslations } from '../../Translations/BillingTranslations'
import Breadcrumbs from '../../Common/Breadcrumbs';
import TableHeader from '../../Common/TableHeader';
import isEmpty from '../../Utilities/isEmpty';
import ReactToPrint from 'react-to-print';
import { InvoicesDetailsSkeleton } from '../Invoices/InvoicesDeatailsSkeleton';
import { BillingPaymentStatus } from './Billing';
import { ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';
import { Params } from '../Dashboard/Dashboard';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';


const BillingDetails = () => {
  const single_order_billing_details = useSelector((state: ReduxRootState) => state?.billing?.single_order_billing_details);
  const billing_loading = useSelector((state: ReduxRootState) => state?.billing?.billing_loading);
  const i18n = useSelector((state: ReduxRootState) => state?.i18n?.i18n);
  const [sortBy, setSortBy] = useState<string>('');
  const [sortType, setSortType] = useState<string>('');
  const dispatch: any = useDispatch();
  const params = useParams<Params>();
  const location = useLocation();
  // const history = useHistory(); // v5
  const navigate = useNavigate(); // v6
  const printContentRef = useRef<HTMLDivElement>(null);

  const { id, restaurantId } = params;

  useEffect(() => {
    billingTranslations();
    if (id && restaurantId) {
      dispatch(getSingleOrderBilling(id, restaurantId));
    }
    //eslint-disable-next-line
  },[]);

  const handleSorting = (sortBy: string) => {
    let newsortType = sortType === 'ASC' ? 'DESC' : 'ASC';
    single_order_billing_details?.billing?.orders?.sort((a, b) => {
      if (newsortType === 'ASC') {
        if (sortBy === 'delivery_time') {
          let dateA = new Date(a[sortBy]).getTime(), dateB = new Date(b[sortBy]).getTime();
          return dateA - dateB;
        } else {
          //@ts-ignore
          return parseFloat(a[sortBy]) - parseFloat(b[sortBy])
        }
      } else {
        if (sortBy === 'delivery_time') {
          let dateA = new Date(a[sortBy]).getTime(), dateB = new Date(b[sortBy]).getTime();
          return dateB - dateA;
        } else {
          //@ts-ignore
          return parseFloat(b[sortBy]) - parseFloat(a[sortBy])
        }
      }
    });
    setSortBy(sortBy);
    setSortType(newsortType);
  }
  useEffect(() => {
    if (location.search && id && restaurantId) {
      dispatch(getSingleOrderBilling(id, restaurantId, location.search))
    }
    //eslint-disable-next-line
  }, [params, location.search]);

  const getPrintContent = () => {
    const printRefCopy = printContentRef.current?.cloneNode(true) as HTMLElement;

    printRefCopy.style.margin = 'auto';
    printRefCopy.style.padding = '10px';
    printRefCopy.style.width = '80%';
    printRefCopy.style.marginTop = '50px';

    let theFirstChild = printRefCopy?.firstChild;

    let heading = document.createElement('h1');
    heading.style.color = 'white';
    heading.innerText = 'Bill Details';
    heading.style.textAlign = 'center';
    heading.style.marginBottom = '50px';
    heading.style.background = '#ff5e5e';
    heading.style.padding = '20px';

    printRefCopy.insertBefore(heading, theFirstChild);

    printRefCopy?.querySelectorAll('.bg-dark.text-white')
      ?.forEach((th) => th?.classList?.remove('text-white'));
    printRefCopy?.querySelectorAll('.btn-link.d-inline.text-decoration-none, .btn.btn-light.btn-sm.text-danger')
      ?.forEach((link) => link?.remove());
    return printRefCopy
  }
  const goToBillingsHandler = () => {
    // const { state } = history.location || {}; // v5
    const { state } = location || {};
    const { restaurantId } = params;
    if (state) {
      // history.push(`/dashboard/restaurant/${restaurantId}/billing${state}`) // v5 routing
      navigate(`/dashboard/restaurant/${restaurantId}/billing${state}`)
    } else {
      // history.push(`/dashboard/restaurant/${restaurantId}/billing`) // v5 routing
      navigate(`/dashboard/restaurant/${restaurantId}/billing`)
    }
  }
  const {
    adjustments,
    orders,
    orders_from,
    orders_to,
    commission_percentage,
    sales_tax_percentage,
    billing_number,
    commission,
    payment_status,
    food_total,
    total_items,
    total_payout,
  } = single_order_billing_details?.billing || {};
  let total_order_total_items = 0;
  let total_order_food_total = 0;
  let total_order_commission = 0;
  let total_sales_tax = 0;
  let Orders = single_order_billing_details?.billing && (orders ?? [])?.map((order, index) => (
    <tr key={index}>
      <td className='pl-0'>
        {order?.delivery_time}
        <span style={{ display: 'none' }}>
          {total_order_total_items = total_order_total_items + order?.items}
          {total_order_food_total = total_order_food_total + Number(order?.food_total)}
          {total_order_commission = total_order_commission + Number(order?.commission)}
          {total_sales_tax = total_sales_tax + Number(order?.sales_tax_total)}
        </span>
      </td>
      <td>
        {order?.items}
      </td>
      <td>
        ${order?.food_total}
      </td>
      <td>
        ${order?.commission} ({Number(commission_percentage)?.toFixed(2)}%)
      </td>
      <td>
        ${order?.sales_tax_total} ({Number(sales_tax_percentage)?.toFixed(2)}%)
      </td>
    </tr>
  ));

  let totalAdjustment = 0;
  let Adjustments = adjustments?.map((adjustment, index) => {
    totalAdjustment = totalAdjustment + parseFloat(adjustment.price);
    return <tr key={index}>
      <td className='pl-0'>{adjustment?.adjustment_date}</td>
      <td>{adjustment?.description}</td>
      <td>-${adjustment?.price}</td>
    </tr>
  });
  const columns = [
    { label: 'Delivery Date', columnName: 'delivery_time', sortable: true },
    { label: 'Total Items', columnName: 'items', sortable: true },
    { label: 'Food Total', columnName: 'food_total', sortable: true },
    { label: 'Commission', columnName: 'commission', sortable: true },
    { label: 'Sales Tax', columnName: 'sales_tax_total' }
  ];
  const adjustmentColumns = [
    { label: 'Date' },
    { label: 'Description' },
    { label: 'Amount' }
  ];

  const { state } = location || {}; // removed v5

  const linkList = [
    { name: 'Bills', url: `/dashboard/restaurant/${params.restaurantId}/billing${state || ''}`, show: true },
    { name: `Bill # ${billing_number}`, show: true }
  ];
  return (
    <div>
      {single_order_billing_details?.billing && billing_loading === false ? (
        <div>
          <Breadcrumbs linkList={linkList} payment={true} />
          <h2 className='mt-4'>
            <span className='text-secondary cursor-pointer'>
              <span onClick={goToBillingsHandler}>
                <i className='fas fa-arrow-circle-left' />
              </span>
            </span> Bill Details
          </h2>
          <p className='muted mb-4'>
            Please see full details of this bill below.
          </p>
          <div id='printContent' ref={printContentRef}>
            <div className='row mt-4 mb-2'>
              <div className='col-6 col-md-auto m-auto'>
                <h6 className='font-weight-bold'>No. {billing_number}</h6>
                <h6 className='text-muted font-weight-bold'>
                  {BillingPaymentStatus[payment_status!] ?
                    <label className={`text-capitalize badge badge-${BillingPaymentStatus[payment_status!]}`}>
                      {payment_status}
                    </label>
                    : null
                  }
                </h6>
              </div>

              <div className='col-6 col-md-auto m-auto'>
                <h6 className='font-weight-bold'>{total_items}</h6>
                <h6 className='text-muted font-weight-bold'>Items</h6>
              </div>

              <div className='col-6 col-md-auto m-auto'>
                <h6 className='font-weight-bold text-capitalize'>
                  {moment(orders_from)?.format('MM/DD ')} -{' '}
                  {moment(orders_to)?.format('MM/DD')}
                </h6>
                <h6 className='text-muted font-weight-bold'>
                  Ordered Between
                </h6>
              </div>

              <div className='col-6 col-md-auto m-auto'>
                <h6 className='font-weight-bold text-capitalize'>${food_total}</h6>
                <h6 className='text-muted font-weight-bold'>Food Total</h6>
              </div>

              <div className='col-6 col-md-auto m-auto'>
                <h6 className='font-weight-bold text-capitalize'>${commission}</h6>
                <h6 className='text-muted font-weight-bold'>Commission</h6>
              </div>

              <div className='col-6 col-md-auto m-auto'>
                <h6 className='font-weight-bold text-capitalize'>${total_payout}</h6>
                <h6 className='text-muted font-weight-bold'>Payout</h6>
              </div>
            </div>
            <div className='table-responsive'>
              <table className='table'>
                <TableHeader
                  columns={columns}
                  sortingColumn={sortBy}
                  handleSorting={handleSorting}
                />
                <tbody>
                  {Orders}
                  <tr>
                    <td className='pl-0'>
                      <b>{i18n && i18n?.t('Total')}</b>
                    </td>
                    <td>
                      <b> {total_order_total_items} </b>
                    </td>
                    <td>
                      <b>${Number(total_order_food_total)?.toFixed(2)}</b>
                    </td>
                    <td>
                      <b>${Number(total_order_commission)?.toFixed(2)}</b>
                    </td>
                    <td>
                      <b>${Number(total_sales_tax)?.toFixed(2)}</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {!isEmpty(adjustments) &&
              <div className='table-responsive'>
                {/* <h2 className='mb-3'>Adjustments</h2> */}
                <table className='table'>
                  <TableHeader columns={adjustmentColumns} />
                  <tbody>
                    {Adjustments}
                    <tr>
                      <td />
                      <td>
                        <b>{i18n && i18n?.t('Total')}</b>
                      </td>
                      <td>
                        <b>-${totalAdjustment}</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            }
          </div>
          <hr />
          <div className='container-fluid w-100'>
            <ReactToPrint
              trigger={() => {
                return (
                  <button className='btn btn-primary float-right mt-4 ml-2 wide-btn'>
                    <i className='mdi mdi-printer mr-1'></i>Print
                  </button>
                );
              }}
              content={getPrintContent}
              documentTitle='Billing Details'
            />
          </div>
        </div>
      ) : (
        <span>
          <InvoicesDetailsSkeleton />
        </span>
      )}
    </div>
  );
}
export default BillingDetails
import React, { useEffect } from 'react';
// import { useHistory, useLocation } from 'react-router-dom'; // React Router v5
import { useNavigate, useLocation } from 'react-router-dom'; // React Router v6

const NotFoundRoutes = () => {
  const headers = localStorage.getItem('headers');
  // const history = useHistory(); // Old React Router v5 hook
  const navigate = useNavigate(); // New React Router v6 hook
  const location = useLocation();

  useEffect(() => {
    if (headers) {
      // history.push('/dashboard/restaurant'); // Old way (React Router v5)
      navigate('/dashboard/restaurant'); // New way (React Router v6)
    } else {
      // history.push({ pathname: '/', state: { to: location } }); // Old way (React Router v5)
      navigate('/', { state: { to: location } }); // New way (React Router v6)
    }
  }, [navigate, location, headers]);

  return <></>
}
export default NotFoundRoutes;
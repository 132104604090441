// import React, { Component } from 'react';
// import { withRouter, RouteComponentProps } from 'react-router-dom';
// import { StaticContext } from 'react-router';
// import queryString from 'query-string';
// import { connect } from 'react-redux';
// import AllLocationsOrders from '../Orders/AllLocationsOrdersListing';
// import './dashboard.css';
// // import Menus from '../Menus/Menus';
// import SingleOrderDetails from '../Orders/SingleOrderDetails';
// import Settings from '../Settings/Settings';
// import Help from '../Help/Help';
// import Billing from '../Billings/Billing';
// import BillingDetails from '../Billings/BillingDetails';
// import Invoices from '../Invoices/Invoices';
// import InvoiceDetails from '../Invoices/InvoiceDetails';
// import { addDays } from 'date-fns';
// import { getRestaurantsLocations } from '../../Actions/getRestaurantActions'
// import Header from '../../Common/Header/Header';
// import { ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';
// import { getTimeZoneSpecificDate } from '../../Utilities/getTimeZoneSpecificDate';
// import StatisticsReports from '../Reports/Reports';
// import { getLocalStorageData } from '../../Utilities/getLocalStorageData';
// import store from '../../store';
// import { GET_RESTAURANT_DETAILS } from '../../Actions/types';
// import isEmpty from '../../Utilities/isEmpty';
// import { GetRestaurantLocations, RestaurantLocation } from '../../Interfaces/PayloadsAndResponses/GetRestaurants';
// import { WithTranslation } from 'react-i18next';
// import Breadcrumbs from '../../Common/Breadcrumbs';
// import MenuPage from '../Menus/MenuPages/MenuPage';
// import MenuSection from '../Menus/MenuPages/MenuSection';
// import MenuTypeSections from '../Menus/MenuPages/MenuTypeSections';
// import ItemPage from '../Menus/MenuPages/ItemPage';
// // import Menus from '../Menus/Menus';
// // import Breakfast from '../Menus/Pages/Breakfast';

// export enum MenuType {
//   BREAKFAST = 'breakfast',
//   LUNCH = 'lunch',
//   DINNER = 'dinner',
//   BUFFET = 'buffet',
// }

// export type Params = {
//   restaurantId?: string;
//   page?: string;
//   id?: string;
// }

// export type DateRange = {
//   startDate: Date;
//   endDate: Date;
//   key: string;
// }

// type DashboardProps = {
//   // mapStateToProps
//   restaurant_addresses: GetRestaurantLocations | null;
//   i18n: WithTranslation | null;
//   // mapDispatchToProps
//   getRestaurantsLocations: (successCallback: (restaurantsList: GetRestaurantLocations) => void) => void
// } & RouteComponentProps<Params, StaticContext, { redirectedFrom: string }>;

// type DashboardState = {
//   dateRange: DateRange[];
// }

// const { vendor: { time_zone } } = getLocalStorageData();
// const { from, to } = queryString.parse(window.location.search);

// class Dashboard extends Component<DashboardProps, DashboardState> {
//   state: DashboardState = {
//     dateRange: [
//       {
//         startDate: from ? getTimeZoneSpecificDate(time_zone, `${from}`) : new Date(),
//         endDate: to ? getTimeZoneSpecificDate(time_zone, `${to}`) : addDays(new Date(), 29),
//         key: 'selection'
//       }
//     ],
//   }

//   selectRequiredRestaurant = (restaurantsList: GetRestaurantLocations) => {
//     const { addresses } = restaurantsList || {};
//     if (addresses && addresses[0]) {
//       const { restaurantId, page } = this.props.match.params;
//       const { remembered_restaurant } = getLocalStorageData();
//       const validRemberedRestaurant = (restaurantId ?? remembered_restaurant) && addresses?.find(restaurant => restaurant.id === +(restaurantId ?? remembered_restaurant));
//       const preSelectedRestaurant = validRemberedRestaurant || addresses[0];
//       localStorage.setItem('remember_restaurant', `${preSelectedRestaurant.id}`);
//       localStorage.setItem('remember_restaurant_id', `${preSelectedRestaurant.id}`);
//       localStorage.setItem('orderPresent', `${preSelectedRestaurant.order_presence}`);
//       localStorage.setItem('isSelfServiceEnable', `${preSelectedRestaurant.enable_self_service}`);
//       if (restaurantId) {
//         if (page) { // If user land on other than Root Page (Setting Page).
//           // Update Current Restaurant Details in reducer and localstorage to get information for showing Tabs in header (Invoices/Billing and Orders).
//           store.dispatch({
//             type: GET_RESTAURANT_DETAILS,
//             payload: { address: preSelectedRestaurant }
//           });
//         }
//       } else {
//         this.props.history.push(`/dashboard/restaurant/${preSelectedRestaurant?.id}`);
//       }
//     }
//   }
//   componentDidMount() {
//     // setTimeout(() => {
//     //   window.hideZendeskWidged();
//     // }, 500);
//     // const { page, restaurantId } = this.props.match.params;
//     this.props.getRestaurantsLocations(this.selectRequiredRestaurant);
//     // if (page === 'menu' && restaurantId) {
//     //   this.props.getRestaurantDetails(restaurantId);
//     // }
//     // this.setState({ didmount: true });
//     // if ((this.props.match.params.restaurantId) && (localStorage.getItem('remember_restaurant') !== this.props.match.params.restaurantId)) {
//     //   localStorage.setItem('remember_restaurant', this.props.match.params.restaurantId);
//     // }
//     if (document.title !== 'Chowmill') {
//       document.title = 'Chowmill';
//     }
//   }
//   // changeTab = (url: string) => {
//   //   this.props.history.push(url);
//   //   if (this.props.match.params.page === 'menu' && this.menuComponent?.current !== null) {
//   //     this.menuComponent && this.menuComponent.getMenuType(url.split('?')[1]);
//   //   }
//   // }
//   // openModal = () => {
//   //   this.setState({ isModalOpen: true });
//   // }
//   // closeModal = () => {
//   //   this.setState({ isModalOpen: false });
//   // }
//   // hasMenu = () => {
//   //   this.setState({ hasMenu: true });
//   // }
//   componentWillReceiveProps(nextProps: DashboardProps) {
//     // if (nextProps.location.search && this.props.location.search !== nextProps.location.search) {
//     //   if (nextProps.match.params.page === 'menu' && this.menuComponent !== null) {
//     //     //Donot dispatch getMenuType if redirected from New Menu Item in Edit Title Popup Component
//     //     this.props.history?.location?.state?.redirectedFrom !== 'NewMenuItem' && this.menuComponent && this.menuComponent.getMenuType(nextProps.location.search.split('?')[1]);
//     //   }
//     // }
//     // if (nextProps.restaurant_addresses && nextProps.restaurant_addresses.addresses && nextProps.restaurant_addresses.addresses[0] && nextProps.restaurant_addresses.addresses[0].id && this.state.didmount) {
//     //   let remember_restaurant: any = localStorage.getItem('remember_restaurant');
//     //   const isSelfServiceEnable = JSON.parse(localStorage?.getItem('isSelfServiceEnable') || '{}')
//     //   let page = this.props.match.params.page;
//     //   let id = this.props.match.params.id;
//     //   if ((!id) && page !== 'menu' && (!localStorage.getItem('view_required_page'))) {
//     //     const validRemberedRestaurant = nextProps.restaurant_addresses.addresses?.find((restaurant: any) => restaurant.id === +remember_restaurant);
//     //     if (remember_restaurant && validRemberedRestaurant) {
//     //       this.props.getRestaurantDetails(remember_restaurant);
//     //       this.props.getRestaurantAdmins(remember_restaurant);
//     //       isSelfServiceEnable !== false && this.props.getDrivers(remember_restaurant);
//     //       this.props.history.push(`/dashboard/restaurant/${remember_restaurant}${page ? '/' + page : ''}${isEmpty(window.location.search) ? '' : '?' + queryString.stringify(this.props.queryString)}`);
//     //       nextProps.restaurant_addresses.addresses.forEach((address: any) => {
//     //         if (address.id === parseFloat(remember_restaurant)) {
//     //           localStorage.setItem('orderPresent', address.order_presence);
//     //         }
//     //       });
//     //     } else {
//     //       const { id: defaultRestaurantId, order_presence, enable_self_service } = nextProps.restaurant_addresses.addresses[0];
//     //       this.props.getRestaurantDetails(defaultRestaurantId);
//     //       this.props.getRestaurantAdmins(defaultRestaurantId);
//     //       enable_self_service !== false && this.props.getDrivers(defaultRestaurantId);
//     //       this.props.history.push(`/dashboard/restaurant/${defaultRestaurantId}`);
//     //       localStorage.setItem('remember_restaurant', defaultRestaurantId);
//     //       localStorage.setItem('orderPresent', order_presence);
//     //     }
//     //   } else {
//     //     if (page !== 'orders' && page !== 'meeting') {
//     //       if (!isEmpty(localStorage.getItem('view_required_page'))) {
//     //         this.props.history.push(`${localStorage.getItem('view_required_page')}`);
//     //       }
//     //     }
//     //   }
//     //   this.setState({ didmount: false });
//     // }
//   }
//   changeRestaurant = (restaurant: RestaurantLocation) => {
//     const { id: newSelectedRestaurant, order_presence, enable_self_service, billing_tab } = restaurant;
//     let { restaurantId, page } = this.props.match.params;
//     if (restaurantId && +restaurantId !== newSelectedRestaurant) {
//       // These checks are required because Invoices and Billing Page are Interchangeable (ONE AT A TIME);
//       if (page === `billing`) {
//         page = enable_self_service ? `invoices` : (billing_tab ? page : ``);
//       } else if (page === `invoices`) {
//         page = enable_self_service ? page : (billing_tab ? `billing` : ``);
//       }
//       store.dispatch({
//         type: GET_RESTAURANT_DETAILS,
//         payload: { address: restaurant }
//       });
//       localStorage.setItem('remember_restaurant', `${newSelectedRestaurant}`);
//       localStorage.setItem('orderPresent', `${order_presence}`);
//       localStorage.setItem('isSelfServiceEnable', `${enable_self_service}`);
//       const menuPage = page === 'menu' || false;
//       const localStorageMenuType = menuPage ? localStorage.getItem('menuType') ? localStorage.getItem('menuType') : 'lunch' : '';
//       // this.props.history.push(`/dashboard/restaurant/${newSelectedRestaurant}${page ? `/${page}` : ``}`); // URL not redirecting to correct menu in case of menu page restaurant selection
//       this.props.history.push(`/dashboard/restaurant/${newSelectedRestaurant}${page ? `/${menuPage ? `menu?menu=${localStorageMenuType}` : page}` : ``}`);
//     }
//   }

//   render() {
//     const { restaurantId, page, id } = this.props.match.params;
//     let restaurant_addresses = this.props.restaurant_addresses && this.props.restaurant_addresses.addresses;
//     const { menu: menuType } = queryString.parse(window.location.search)
//     let Component = <Settings />;
//     if (page === 'sections') {
//       Component = <MenuTypeSections />;
//     } else if (page === 'items') {
//       Component = <MenuSection />;
//     } else if (page === 'item') {
//       Component = <ItemPage />
//     } else if (page === 'menu') {
//       Component = (
//         <MenuPage />
//         // <Menus
//         //   menuType={(menuType ?? MenuType.LUNCH) as MenuType}
//         // />
//       );
//     } else if (page === 'meetings' && isEmpty(id)) {
//       Component = <AllLocationsOrders handleDashBoardState={(range) => this.setState({ dateRange: range })}  dateRange={this.state.dateRange} />;
//     } else if (page === 'meetings' && !isEmpty(id)) {
//       Component = <SingleOrderDetails />;
//     } else if (page === 'help') {
//       Component = <Help />;
//     } else if (page === 'billing') {
//       if (id) {
//         Component = <BillingDetails />
//       } else {
//         Component = <Billing />;
//       }
//     } else if (page === 'invoices') {
//       if (id) {
//         Component = <InvoiceDetails />
//       } else {
//         Component = <Invoices />;
//       }
//     }
//     // Payments Page is temporarily hidden from vendor
//     // else if (page === 'payments') {
//     //   Component = <StripePayment />;
//     // }
//     else if (page === 'reports') {
//       Component = <StatisticsReports />
//     }

//     const locationDropdown = restaurant_addresses && restaurant_addresses?.length > 1 ? (
//       <div className='dropdown me-2 flex-grow'>
//         <div
//           className='btn btn-link dropdown-toggle noLinkEffect font-1 px-3'
//           id='dropdownMenuButton'
//           data-bs-toggle='dropdown'
//           aria-haspopup='true'
//           aria-expanded='false'
//         >
//           {this.props.i18n && this.props.i18n.t('Select Restaurant')}
//         </div>
//         <div
//           className='dropdown-menu invisible-scroll'
//           aria-labelledby='dropdownMenuButton'
//           style={{ left: -158, top: 46, overflowY: 'scroll', maxHeight: 'calc(100vh - 100px)' }}
//         >
//           {restaurant_addresses && restaurant_addresses.map((restaurant, index: number) => (
//             <h6
//               key={index}
//               className={`dropdown-header restaurant-names-${parseFloat(`${restaurantId ?? ''}`) === restaurant.id ? 'active' : ''} cursor-pointer`}
//               onClick={() => this.changeRestaurant(restaurant)}
//               style={{ whiteSpace: 'normal' }}
//             >
//               {/* Restaurant Name */}
//               <div>{restaurant.name?.split(':')[0]}</div>
//               {/* Location Name */}
//               <div>{restaurant.name?.split(':')[1]}</div>
//               {/* Location */}
//               <div>{restaurant.name?.split(':')[2]}</div>
//             </h6>
//           ))}
//         </div>
//       </div>
//     ) : '';
//     const linkListUpdated = [
//       { name: `Menu`, url: `/dashboard/restaurant/${restaurantId}/menu/`, show: true },
//       { name: 'Lunch', url: `/dashboard/restaurant/${restaurantId}/sections/`, show: true },
//       { name: 'Starter', url: `/dashboard/restaurant/${restaurantId}/items/`, show: true },
//       { name: 'Biryani', url: `/dashboard/restaurant/${restaurantId}/item/item?item=${menuType}`, show: true }
//       // { name: 'Section', show:true },
//       // {name: 'Food Item',show:true }
//     ];
//     return (
//       <div className='container-scroller'>
//         <Header locationDropdown={locationDropdown} />
//         <div className='page-body-wrapper'>
//           <div className='main-panel'>
//             {(page === 'menu') && <div className="menu_page_header_image"></div>}
//             <div className="container z-index-1">
//               {(page === 'menu') && <><Breadcrumbs linkList={linkListUpdated} />
//                 <div className="d-flex justify-content-between align-items-center mt-3 mb-5">
//                   <h2 className='menu_page_headings m-0'>Menus</h2>
//                   <div className='dropdown'>
//                     <div className='dropdown-toggle font-16 cursor-pointer menu_card_dropdown'
//                       id='dropdownMenuButton'
//                       data-bs-toggle='dropdown'
//                       aria-haspopup='true'
//                       aria-expanded='false'
//                     >
//                       <b><i className="fa-sharp fa-solid fa-store menu_card_shop_icon"></i></b>
//                       <span className='fs-16 fw-600 m-0'>{this.props.i18n && this.props.i18n?.t('Curry Up Now')}</span>
//                     </div>
//                     <div
//                       className='dropdown-menu main_menu'
//                       aria-labelledby='dropdownMenuButton'
//                       style={{ left: -158, top: 46, overflowY: 'scroll', maxHeight: 'calc(100vh - 58vh)', borderRadius: '5px', }}
//                     >
//                       {restaurant_addresses && restaurant_addresses?.map((restaurant, index: number) => (
//                         <h6
//                           key={index}
//                           className={`dropdown-header px-3 restaurant-names-${parseFloat(`${restaurantId ?? ''}`) === restaurant.id ? 'active' : ''} cursor-pointer`}
//                           onClick={() => this.changeRestaurant(restaurant)}
//                           style={{ whiteSpace: 'normal' }}
//                         >
//                           <div className="d-flex align-items-start">
//                             <input type="checkbox" className='mt-1 me-1' />
//                             <div className="ms-3 fff">
//                               {/* Restaurant Name */}
//                               <div className='text-medium-black fw-normal fs-16'>{restaurant.name?.split(':')[0]}</div>
//                               {/* Location Name */}
//                               <div>{restaurant.name?.split(':')[1]}</div>
//                               {/* Location */}
//                               <div className='text-medium-black fw-normal fs-16'>{restaurant.name?.split(':')[2]}</div>
//                             </div>
//                           </div>
//                         </h6>
//                       ))}
//                     </div>
//                   </div>
//                 </div>
//               </>
//               }
//               <div className='' style={['meetings'].includes(page ?? '') ? { background: '#f4f8fb' } : {}}>
//                 <div className='row'>
//                   <div className='col-md-12 cursor-default'>{Component}</div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

// const mapStateToProps = (state: ReduxRootState) => ({
//   restaurant_addresses: state.restaurant_data.restaurant_addresses,
//   i18n: state.i18n.i18n
// })

// export type DashboardRef = React.ElementRef<typeof Dashboard>;

// export default connect(mapStateToProps, { getRestaurantsLocations })(withRouter(Dashboard));

import React, { useEffect, useState } from 'react';
// import { useHistory, useParams } from 'react-router'; // React Router v5
import { useNavigate, useParams } from 'react-router-dom'; // React Router v6
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import AllLocationsOrders from '../Orders/AllLocationsOrdersListing';
import './dashboard.css';
import SingleOrderDetails from '../Orders/SingleOrderDetails';
import Settings from '../Settings/Settings';
import Help from '../Help/Help';
import Billing from '../Billings/Billing';
import BillingDetails from '../Billings/BillingDetails';
import Invoices from '../Invoices/Invoices';
import InvoiceDetails from '../Invoices/InvoiceDetails';
import { addDays } from 'date-fns';
import { getRestaurantsLocations } from '../../Actions/getRestaurantActions'
import Header from '../../Common/Header/Header';
import { ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';
import { getTimeZoneSpecificDate } from '../../Utilities/getTimeZoneSpecificDate';
import StatisticsReports from '../Reports/Reports';
import { getLocalStorageData } from '../../Utilities/getLocalStorageData';
import store from '../../store';
import { GET_RESTAURANT_DETAILS } from '../../Actions/types';
import isEmpty from '../../Utilities/isEmpty';
import { GetRestaurantLocations, RestaurantLocation } from '../../Interfaces/PayloadsAndResponses/GetRestaurants';
import MenuPage from '../Menus/MenuPages/MenuPage';
import MenuSection from '../Menus/MenuPages/MenuSection';
import ItemPage from '../Menus/MenuPages/ItemPage';
import { useDispatch } from 'react-redux';
import MenuSectionsPage from '../Menus/MenuPages/MenuSectionsPage';
// import { Section } from '../../Interfaces/PayloadsAndResponses/Menu';

export enum MenuType {
  BREAKFAST = 'breakfast',
  LUNCH = 'lunch',
  DINNER = 'dinner',
  BUFFET = 'buffet',
}

export type Params = {
  restaurantId?: string;
  page?: string;
  id?: string;
}

export type DateRange = {
  startDate: Date;
  endDate: Date;
  key: string;
}
const { vendor: { time_zone } } = getLocalStorageData();
const { from, to } = queryString.parse(window.location.search);

const Dashboard = () => {

  const params = useParams<Params>();
  const dispatch: any = useDispatch();
  // const history = useHistory(); // React Router v5 hook
  const navigate = useNavigate(); // React Router v6 hook

  const i18n = useSelector((state: ReduxRootState) => state.i18n.i18n);
  const restaurant_addresses = useSelector((state: ReduxRootState) => state?.restaurant_data?.restaurant_addresses);
  const sectionData = JSON.parse(localStorage.getItem('section') || '{}')
  const fooditem = localStorage.getItem('fooditem') ?? ''
  const [dateRange, setDateRange] = useState<DateRange[]>(
    [
      {
        startDate: from ? getTimeZoneSpecificDate(time_zone, `${from}`) : new Date(),
        endDate: to ? getTimeZoneSpecificDate(time_zone, `${to}`) : addDays(new Date(), 29),
        key: 'selection'
      }
    ]
  );

  // const [breadcrumbs, setBreadcrumbs] = useState({
  //   section: {},
  //   sectionName: '',
  //   fooditemName: '',
  // })

  // const menuBreadcrumbshandler = (section:Section) => {
  //   setBreadcrumbs({
  //     ...breadcrumbs,
  //     section: section,
  //     sectionName: `${section?.name}`,
  //   })
  // }

  const selectRequiredRestaurant = (restaurantsList: GetRestaurantLocations) => {
    const { addresses } = restaurantsList || {};
    if (addresses && addresses[0]) {
      const { restaurantId, page } = params;
      const { remembered_restaurant } = getLocalStorageData();
      const validRemberedRestaurant = (restaurantId ?? remembered_restaurant) && addresses?.find(restaurant => restaurant.id === +(restaurantId ?? remembered_restaurant));
      const preSelectedRestaurant = validRemberedRestaurant || addresses[0];
      localStorage.setItem('remember_restaurant', `${preSelectedRestaurant.id}`);
      localStorage.setItem('remember_restaurant_id', `${preSelectedRestaurant.id}`);
      localStorage.setItem('orderPresent', `${preSelectedRestaurant.order_presence}`);
      localStorage.setItem('isSelfServiceEnable', `${preSelectedRestaurant.enable_self_service}`);
      if (restaurantId) {
        if (page) { // If user land on other than Root Page (Setting Page).
          // Update Current Restaurant Details in reducer and localstorage to get information for showing Tabs in header (Invoices/Billing and Orders).
          store.dispatch({
            type: GET_RESTAURANT_DETAILS,
            payload: { address: preSelectedRestaurant }
          });
        }
      } else {
        // history.push(`/dashboard/restaurant/${preSelectedRestaurant?.id}`); // Old React Router v5 (commented)
        navigate(`/dashboard/restaurant/${preSelectedRestaurant?.id}`); // New React Router v6
      }
    }
  };

  useEffect(() => {
    const newWindow = localStorage.getItem('newwindow')
    if (newWindow === 'true') {
      localStorage.removeItem('newwindow')
      window.location.reload()

    }
    dispatch(getRestaurantsLocations(selectRequiredRestaurant));
    if (document.title !== 'Chowmill') {
      document.title = 'Chowmill';
    }
    //eslint-disable-next-line
  }, []);

  const changeRestaurant = (restaurant: RestaurantLocation) => {
    const { id: newSelectedRestaurant, order_presence, enable_self_service, billing_tab } = restaurant;
    let { restaurantId, page } = params;
    if (restaurantId && +restaurantId !== newSelectedRestaurant) {
      // These checks are required because Invoices and Billing Page are Interchangeable (ONE AT A TIME);
      if (page === `billing`) {
        page = enable_self_service ? `invoices` : (billing_tab ? page : ``);
      } else if (page === `invoices`) {
        page = enable_self_service ? page : (billing_tab ? `billing` : ``);
      }
      store.dispatch({
        type: GET_RESTAURANT_DETAILS,
        payload: { address: restaurant }
      });
      localStorage.setItem('remember_restaurant', `${newSelectedRestaurant}`);
      localStorage.setItem('orderPresent', `${order_presence}`);
      localStorage.setItem('isSelfServiceEnable', `${enable_self_service}`);
      const menuPage = page === 'menu' || false;
      const localStorageMenuType = menuPage ? localStorage.getItem('menuType') ? localStorage.getItem('menuType') : 'lunch' : '';
      // this.props.history.push(`/dashboard/restaurant/${newSelectedRestaurant}${page ? `/${page}` : ``}`); // Old React Router v5 (commented)
      navigate(`/dashboard/restaurant/${newSelectedRestaurant}${page ? `/${menuPage ? `menu?menu=${localStorageMenuType}` : page}` : ``}`); // New React Router v6
    }
  };

  const { restaurantId, page, id } = params;

  const checkSection = (page === 'sections' || page === 'items' || page === 'item') ? true : false
  const checkFoodItems = (page === 'items' || page === 'item') ? true : false
  const checkFoodItem = page === 'item' ? true : false
  const menuTypeLocalStorage = localStorage.getItem('menuType') ?? ''


  const linkListUpdated = [
    { name: `Menu`, url: `/dashboard/restaurant/${restaurantId}/menu/`, show: true },
    { name: `${menuTypeLocalStorage?.charAt(0)?.toUpperCase() + menuTypeLocalStorage?.slice(1)}`, url: `/dashboard/restaurant/${restaurantId}/sections/`, show: checkSection },
    { name: `${sectionData?.name?.charAt(0)?.toUpperCase() + sectionData?.name?.slice(1)}`, url: `/dashboard/restaurant/${restaurantId}/items/`, show: checkFoodItems },
    { name: `${fooditem?.charAt(0)?.toUpperCase() + fooditem?.slice(1)}`, url: `/dashboard/restaurant/${restaurantId}/item/item?item=${fooditem}`, show: checkFoodItem }
  ];
  let _restaurant_addresses = restaurant_addresses && restaurant_addresses?.addresses;
  // const { menu: menuType } = queryString.parse(window.location.search)
  let Component = <Settings />;
  if (page === 'sections') {
    Component = <MenuSectionsPage linkListUpdated={linkListUpdated} />;
  } else if (page === 'items') {
    Component = <MenuSection linkListUpdated={linkListUpdated} />;
  } else if (page === 'item') {
    Component = <ItemPage linkListUpdated={linkListUpdated} />
  } else if (page === 'menu') {
    Component = (
      <MenuPage />
      // <Menus
      //   menuType={(menuType ?? MenuType.LUNCH) as MenuType}
      // />
    );
  } else if (page === 'meetings' && isEmpty(id)) {
    Component = <AllLocationsOrders handleDashBoardState={(range) => setDateRange(range)} dateRange={dateRange} />;
  } else if (page === 'meetings' && !isEmpty(id)) {
    Component = <SingleOrderDetails />;
  } else if (page === 'help') {
    Component = <Help />;
  } else if (page === 'billing') {
    if (id) {
      Component = <BillingDetails />
    } else {
      Component = <Billing />;
    }
  } else if (page === 'invoices') {
    if (id) {
      Component = <InvoiceDetails />
    } else {
      Component = <Invoices />;
    }
  }
  // Payments Page is temporarily hidden from vendor
  // else if (page === 'payments') {
  //   Component = <StripePayment />;
  // }
  else if (page === 'reports') {
    Component = <StatisticsReports />
  }

  let backgroundimage = <div></div>;
  if (page === 'sections') {
    backgroundimage = <div className="section_page_header_image"></div>
  } else if (page === 'items') {
    backgroundimage = <div className="section_page_header_image"></div>
  } else if (page === 'item') {
    backgroundimage = <div className="section_page_header_image"></div>
  } else if (page === 'menu') {
    backgroundimage = <div className="menu_page_header_image"></div>
  }

  const locationDropdown = (!(page === 'menu' || page === 'sections' || page === 'items' || page === 'item')) && _restaurant_addresses && _restaurant_addresses?.length > 1 ? (
    <div className='dropdown me-2 flex-grow'>
      <div
        className='btn btn-link dropdown-toggle noLinkEffect font-1 px-3'
        id='dropdownMenuButton'
        data-bs-toggle='dropdown'
        aria-haspopup='true'
        aria-expanded='false'
      >
        {i18n && i18n?.t('Select Restaurant')}
      </div>
      <div
        className='dropdown-menu invisible-scroll'
        aria-labelledby='dropdownMenuButton'
        style={{ left: -158, top: 46, overflowY: 'scroll', maxHeight: 'calc(100vh - 100px)' }}
      >
        {_restaurant_addresses && _restaurant_addresses?.map((restaurant: RestaurantLocation, index: number) => (
          <h6
            key={index}
            className={`dropdown-header restaurant-names-${parseFloat(`${restaurantId ?? ''}`) === restaurant.id ? 'active' : ''} cursor-pointer`}
            onClick={() => changeRestaurant(restaurant)}
            style={{ whiteSpace: 'normal' }}
          >
            {/* Restaurant Name */}
            <div>{restaurant.name?.split(':')[0]}</div>
            {/* Location Name */}
            <div>{restaurant.name?.split(':')[1]}</div>
            {/* Location */}
            <div>{restaurant.name?.split(':')[2]}</div>
          </h6>
        ))}
      </div>
    </div>
  ) : '';

  return (
    <div className='container-scroller'>
      <Header locationDropdown={locationDropdown} />
      <div className='page-body-wrapper'>
        <div className={`main-panel ${page === 'meetings' && !isEmpty(id) ? 'single-order-detail' : ''}`}>
          {page === 'meetings' && <div className={`main-panel-upcoming-orders ${!isEmpty(id) ? 'orders-details-page' : 'all-upcoming-orders'}`}></div>}
          {backgroundimage}
          <div className="container z-index-1">
            <div style={['meetings'].includes(page ?? '') ? { background: '#f4f8fb' } : {}}>
              <div className='row'>
                <div className='col-md-12 cursor-default'>{Component}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom'; // React Router v6
// import { Route, Redirect, useLocation } from 'react-router-dom'; // React Router v5
import { useSelector } from 'react-redux';
import { ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';

type PrivateRouteProps = {
  element: any;
  path?: string | Array<string>;
  exact?: boolean;
};

const PrivateRoute = ({ element: Element, ...rest }: PrivateRouteProps) => {
  const location = useLocation();
  const { auth } = useSelector((state: ReduxRootState) => state);
  const profileCompleteStatus = JSON.parse(localStorage.getItem('profile-complete') || '{}')

  // React Router v5 implementation (commented for reference)
  // return (
  //   <Route
  //     {...rest}
  //     render={(props) => {
  //       if (auth.isAuthenticated === true && profileCompleteStatus !== 'confirmed-token') {
  //         return <Component {...props} />;
  //       }
  //       if (profileCompleteStatus === 'confirmed-token') {
  //         return <Redirect to="/signup" />;
  //       }
  //       return <Redirect to={{ pathname: '/', state: { from: location } }} />;
  //     }}
  //   />
  // );

  // React Router v6 implementation
  if (auth.isAuthenticated === true && profileCompleteStatus !== 'confirmed-token') {
    return <Element {...rest}/>;
  }
  if (profileCompleteStatus === 'confirmed-token') {
    return <Navigate to="/signup" />;
  }
  return <Navigate to="/" state={{ from: location }} />;

};

export default PrivateRoute;

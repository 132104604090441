import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom'; // React Router v6 update
import { getRedirectURL } from '../../Actions/authActions';
import Spinner from '../../Common/Spinner';

const ShortURL = () => {
  const dispatch: any = useDispatch();
  const { token } = useParams<{ token: string }>();
  // const history = useHistory(); // React Router v5: Old history hook
  const navigate = useNavigate(); // React Router v6: New navigate hook

  useEffect(() => {
    token && dispatch(getRedirectURL(token, navigate)); // Using navigate instead of history
  }, [dispatch, navigate, token]);

  return <div className='min-vw-100 min-vh-100 d-flex justify-content-center align-items-center'>
    <Spinner />
  </div>
}
export default ShortURL;

import React, { useEffect, useState } from "react"
import CustomPopUp from "../../../Common/Popups/CustomPopUp";
import { useSelector } from "react-redux";
import { ReduxRootState } from "../../../Interfaces/ReduxInterface/ReduxRootState";
import { useDispatch } from "react-redux";
import { addNewMenuSection, addNewSection, dragAndDropMenuSection, getMenuSections, getMenuType, hideSection } from "../../../Actions/menuActions";
import { Section } from "../../../Interfaces/PayloadsAndResponses/Menu";
import MenuSectionCard from "./MenuSectionCard";
import MenuSectionsSkeleton from "./MenuSectionsSkeleton";
// import { useHistory, useParams } from 'react-router'; // React Router v5
import { useNavigate, useParams } from 'react-router-dom'; // React Router v6
import Breadcrumbs from "../../../Common/Breadcrumbs";
import AddEditSectionPopup from "./AddEditSectionPopup";
import Spinner from "../../../Common/Spinner";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Params } from "../../Dashboard/Dashboard";
import { Link } from "react-router-dom";
import isEmpty from "../../../Utilities/isEmpty";

//Create a custom style and use all over menu page Custom popup
export const customStyles: any = {
  left: '25%',
  zIndex: '1101',
  position: 'fixed',
  width: '50%',
  right: '12%',
  backgroundColor: 'white',
  borderRadius: "10px"
};

const MenuSectionsPage = ({ linkListUpdated }: any) => {

  const dispatch: any = useDispatch()
  // const history = useHistory(); // React Router v5 hook
  const navigate = useNavigate(); // React Router v6 hook
  const { restaurantId } = useParams<Params>();
  const menuSections = useSelector((state: ReduxRootState) => state.menus?.menuTypeSections)
  const restaurant_addressesData: any = useSelector((state: ReduxRootState) => state.restaurant_data?.restaurant_details);
  const updatedSections = useSelector((state: ReduxRootState) => state.menus?.updatedSections);
  const menuTypeLocalStorage = localStorage.getItem('menuType') ?? ''
  const selected_restaurant_id = localStorage.getItem('remember_restaurant') ?? ''
  const selectedMenuId = localStorage.getItem('menuTypeid') ?? ''
  const addingNewMenuSection = localStorage.getItem('addingNewMenu') ?? ''

  const [isOpenNewSection, setIsOpenNewSection] = useState<boolean>(false)
  const [isOpenEditSection, setIsOpenEditSection] = useState<boolean>(false)
  const [isDuplicateSection, setIsDuplicateSection] = useState<boolean>(false)
  const [selectedSection, setSelectedSection] = useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [dragListItem, setDragListItem] = useState<any>(updatedSections?.sections);
  const updatedSectionsLoading = useSelector((state: ReduxRootState) => state.menus?.loading_section)

  const [newSectionData, setnewSectionData] = useState({
    section_name: '',
    section_description: '',
    section_visibility: false,
    position: 1
  })

  const isHiddenChanged = updatedSections?.sections?.some((section, index) => {
    const previousSection = dragListItem?.[index];
    return previousSection && section?.is_hidden !== previousSection?.is_hidden;
  });

  useEffect(() => {
    setDragListItem(updatedSections?.sections);
    //eslint-disable-next-line
  }, [updatedSections?.sections?.length, isHiddenChanged])

  const handleChange = (e: any) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === 'checkbox' ? checked : value;

    setnewSectionData((prevData) => ({
      ...prevData,
      [name]: fieldValue,
    }));
  };
  const handleChangeCheckbox = (e: any) => {
    setnewSectionData((prevData) => ({
      ...prevData,
      section_visibility: !newSectionData?.section_visibility,
    }));
  };
  const loaderHandler = () => {
    setIsOpenNewSection(false)
    setIsLoading(false)
  }
  useEffect(() => {

  }, [isLoading])
  const handleSubmit = (e: any) => {
    setIsLoading(true)
    e.preventDefault();
    const menuId = localStorage.getItem('menuTypeid')
    const menuTypeSelected = localStorage.getItem('menuType')
    let menuType
    if (menuTypeSelected === 'lunch') {
      menuType = 1
    } else if (menuTypeSelected === 'dinner') {
      menuType = 2
    } else if (menuTypeSelected === 'breakfast') {
      menuType = 3
    } else if (menuTypeSelected === 'buffet') {
      menuType = 4
    } else if (menuTypeSelected === 'dont_care') {
      menuType = 0
    }
    const addNewSectionPayload = {
      section: {
        name: newSectionData?.section_name,
        description: newSectionData?.section_description,
        position: 1,
        is_hidden: !newSectionData?.section_visibility
      }
    }
    // setIsOpenNewSection(false)
    if (isEmpty(addingNewMenuSection)) {
      menuId && restaurant_addressesData && dispatch(addNewSection(addNewSectionPayload, menuId, menuTypeLocalStorage, restaurant_addressesData?.address?.id, loaderHandler))
    } else if (addingNewMenuSection === 'true') {
      const addingNewMenuAndSection = {
        menu: {
          menu_type: menuType,
          sections_attributes: [{ ...addNewSectionPayload.section }]
        }
      }
      restaurant_addressesData && dispatch(addNewMenuSection(restaurant_addressesData?.address?.id, addingNewMenuAndSection, loaderHandler))
    }

  };
  const hideSectionHandler = (section: Section) => {
    const menuId = localStorage.getItem('menuTypeid')
    const hideSectionPayload = {
      section: {
        is_hidden: !section?.is_hidden,
        position: section?.position
      }
    }
    menuId && restaurant_addressesData && dispatch(hideSection(hideSectionPayload, menuId, restaurant_addressesData?.address?.id, section?.id))
  }
  const addEditSectionPopup = (type: string, section?: Section) => {
    if (type === 'add') {
      setIsOpenNewSection(true)
    } else if (type === 'edit' || type === 'duplicate') {
      type === 'edit' && setIsOpenEditSection(true)
      type === 'duplicate' && setIsDuplicateSection(true)
      section && setSelectedSection(section)
    }
  }
  const onPopUpCloseAddEditSection = () => {
    setIsOpenEditSection(false)
    setIsDuplicateSection(false)
  }

  const sectionItems = (section: Section) => {
    localStorage.setItem('section', JSON.stringify(section));
    const restaurantId = restaurant_addressesData?.address?.id ?? +selected_restaurant_id
    // history.push(`/dashboard/restaurant/${restaurantId}/items/`) // v5 old routing
    navigate(`/dashboard/restaurant/${restaurantId}/items/`) // v6 upgrade routing
  }
  useEffect(() => {

  }, [selectedSection])

  useEffect(() => {
    isEmpty(addingNewMenuSection) && restaurant_addressesData?.address?.id && dispatch(getMenuSections(+restaurant_addressesData?.address?.id, +selectedMenuId))

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (isEmpty(addingNewMenuSection)) {

      if (restaurant_addressesData) {
        dispatch(getMenuType(menuTypeLocalStorage, restaurant_addressesData?.address?.id));
      } else if (selected_restaurant_id) {
        dispatch(getMenuType(menuTypeLocalStorage, +selected_restaurant_id));
      }
      restaurant_addressesData?.address?.id && dispatch(getMenuSections(+restaurant_addressesData?.address?.id, +selectedMenuId))
    }

    //eslint-disable-next-line 
  }, [restaurant_addressesData?.address?.id])

  const handleOnDragEnd = (result: any) => {
    if (!result?.destination) return;
    const items = Array?.from(dragListItem);
    const [reorderedItem] = items?.splice(result?.source?.index, 1);
    items?.splice(result?.destination?.index, 0, reorderedItem);
    setDragListItem(items);
    const destination = result?.destination;
    const newPosition = dragListItem && dragListItem[destination?.index]?.position;
    const draggableSectionId = dragListItem?.find(
      (sectionId: any) => sectionId?.id === parseInt(result?.draggableId)
    );
    const updatedSectionData: any = {
      section: {
        position: newPosition
      }
    }
    isEmpty(addingNewMenuSection) && selectedMenuId && restaurant_addressesData && dispatch(dragAndDropMenuSection(updatedSectionData, selectedMenuId, restaurant_addressesData?.address?.id, draggableSectionId?.id))
  };

  return (
    <>
      {<div className="container px-0 mb-5 section-page">
        <section>
          <div className="mb-1">
            <Breadcrumbs linkList={linkListUpdated} />
          </div>
          <div className="d-flex align-items-center">
            <Link to={`/dashboard/restaurant/${restaurantId}/menu`} style={{ color: '#fff', marginBottom: '20px' }}>
              <i className='fa-solid fa-angle-left mr-2'></i>
            </Link>
            <h3 className="text-white section-heading section-container">{menuTypeLocalStorage?.charAt(0)?.toUpperCase() + menuTypeLocalStorage?.slice(1)}</h3>
          </div>
        </section>
        {updatedSectionsLoading ? <MenuSectionsSkeleton /> : <div className="bg-white rounded-10">
          <div className="d-flex justify-content-between align-items-center px-2 px-md-4 py-3 border-bottom">
            <h3 className="fw-600 m-0">Section(s)</h3>
            <button className="btn btn-sm btn-custom-primary rounded-pill px-3 " onClick={() => addEditSectionPopup('add')}><i className="far fa-plus pe-2"></i> New Section</button>
          </div>
          <>
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="characters">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {updatedSections?.sections && updatedSections?.sections?.map((section: Section, index: number) => {
                      return <React.Fragment key={index}>
                        <Draggable draggableId={String(section?.id)} index={index}>
                          {(provided) => (
                            <div {...provided.draggableProps} ref={provided.innerRef}>
                              <MenuSectionCard section={section} dragProps={provided.dragHandleProps} sectionItems={sectionItems} addEditSectionPopup={addEditSectionPopup} hideSectionHandler={hideSectionHandler} />
                              {(menuSections && index !== menuSections?.sections?.length - 1) && <hr className="menu_seprator" />}
                            </div>
                          )}
                        </Draggable>
                      </React.Fragment>
                    })
                    }
                    {provided?.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </>
          <div className="px-4 py-4 " onClick={() => addEditSectionPopup('add')}>
            <button className="menu_list_add_button btn btn-block">
              <i className="fa-light fa-plus fa-lg"></i>
            </button>
          </div>
        </div>
        }
        <div>
          {isOpenNewSection &&
            <CustomPopUp
              backdropStyles={{ opacity: 1, cursor: 'default' }}
              onPopUpClose={() => setIsOpenNewSection(false)}
              popUpBodyStyles={{ ...customStyles, top: '25%', width: '37.5%', left: '32%' }}
              allowScrolling={true}
            >
              <div className="modal-content ">
                <div className="menu-item-modal-header">
                  <h3 className="fw-600 m-0 text-medium-black">Add Section</h3>
                  <button type="button" className="btn-close modal-close-btn text-dark" onClick={() => setIsOpenNewSection(false)} data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body py-0">
                  <form>
                    <div className="mb-3">
                      <label className="fs-16 fw-normal text-medium-gray">Section Name</label>
                      <input type="email" className="form-control form-control-sm rounded-1" id="exampleFormControlInput1" name='section_name' value={newSectionData?.section_name} placeholder="Section Name" onChange={handleChange} />
                    </div>
                    <div className="mb-3">
                      <label className="fs-16 fw-normal text-medium-gray">Description</label>
                      <textarea className="form-control form-control-sm rounded-1" rows={4} name='section_description' value={newSectionData?.section_description} id="exampleFormControlTextarea1" onChange={handleChange}></textarea>
                    </div>
                    <p className="fs-16 fw-normal text-medium-gray">Visibility</p>
                    <div className="mb-3 form-check d-flex align-items-center ps-0">
                      <input type="checkbox" className="form-check-input m-0" id="section_visibility" name='section_visibility' checked={newSectionData?.section_visibility} onChange={handleChangeCheckbox} />
                      <label className="fs-16 fw-normal text-medium-black ps-4 m-0 cursor-pointer" htmlFor='section_visibility'>Visible for Customers</label>
                    </div>
                  </form>
                </div>
                <div className="menu-item-modal-footer justify-content-end">
                  <button type="button" className="btn btn-custom-outline-light me-2 rounded-pill px-32" data-bs-dismiss="modal" onClick={() => setIsOpenNewSection(false)}>Cancel</button>
                  {<button type="submit" className="btn btn-md btn-custom-primary rounded-pill py-2 px-32" onClick={handleSubmit} >{!isLoading ? 'Add' : <Spinner />}</button>}
                </div>
              </div>
            </CustomPopUp>
          }
          {(isOpenEditSection || isDuplicateSection) && <AddEditSectionPopup section={selectedSection} type={isOpenEditSection ? 'Save' : 'Duplicate'} onPopUpClose={onPopUpCloseAddEditSection} />}
        </div>
      </div>
      }
    </>
  )
}
export default MenuSectionsPage
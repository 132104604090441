import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router'; // useHistory deprecated
import { getMenuSections } from '../../../Actions/menuActions';
import { Params } from '../../../Pages/Dashboard/Dashboard';
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ReactTooltip from 'react-tooltip';
import parse from 'html-react-parser';
import breakfastImage from '../../../Images/menu-section.png';
import lunchImage from '../../../Images/menu-section.png';
import dinnerImage from '../../../Images/menu-section.png';
import buffetImage from '../../../Images/menu-section.png';
import { UPDATE_SECTIONS } from '../../../Actions/types';
import isEmpty from '../../../Utilities/isEmpty';


const progressBarStyle = {
  textColor: "white",
  trailColor: "white",
  textSize: "25px",
  strokeLinecap: "butt"
}

const imageProgressBarStyle = {
  ...progressBarStyle,
  pathColor: "green",
}

const taggingProgressBarStyle = {
  ...progressBarStyle,
  pathColor: "#007bff",
}

type MenuCardProps = {
  menuData: any;
  menuType: string;
  getSpecificMenuType: (type: string) => void;
}
const MenuCard: FC<MenuCardProps> = ({ menuType, menuData, getSpecificMenuType }) => {

  // const history = useHistory(); // v5
  const navigate = useNavigate(); // v6
  const dispatch: any = useDispatch();
  const { restaurantId } = useParams<Params>();

  const menuDataObjectCheck = Object?.keys(menuData)?.length !== 0
  const taggingPercentage = menuData?.tagging_percentage ?? 0
  const imagesPercentage = menuData?.images_percentage ?? 0
  const imagesProgressBarText = menuDataObjectCheck ? (`${menuData?.images_percentage}%`) : '0%'
  const taggingProgressBarText = menuDataObjectCheck ? (`${menuData?.tagging_percentage}%`) : '0%'
  const menuCompletenessPercent = Math.ceil(((menuData?.images_percentage ?? 0) + (menuData?.tagging_percentage ?? 0)) / 2)

  const menuSections = () => {
    localStorage.setItem('menuTypeid', menuData.id)
    localStorage.setItem('menuTypelocal', menuType)
    // history.push(`/dashboard/restaurant/${restaurantId}/sections/`); // v5
    navigate(`/dashboard/restaurant/${restaurantId}/sections/`) // v6
    restaurantId && dispatch(getMenuSections(+restaurantId, +menuData?.id))
    getSpecificMenuType(menuType)
  }

  const addMenuSections = () => {
    localStorage.setItem('menuTypeid', menuData.id)
    localStorage.setItem('menuTypelocal', menuType)
    localStorage.setItem('addingNewMenu', 'true')

    // history.push(`/dashboard/restaurant/${restaurantId}/sections/`); // v5
    navigate(`/dashboard/restaurant/${restaurantId}/sections/`)

    getSpecificMenuType(menuType)
  }
  const returnPercentRating = (rating: number, starNumber: number) => {
    if (rating >= starNumber) {
      return '100%'
    }
    else if ((!Number.isInteger(rating)) && (starNumber - rating) < 1) {
      return Number((rating - Math.floor(rating)) * 100) + '%';
    }
  }

  //Default Images
  const defaultSectionImages: any = {
    breakfast: breakfastImage,
    lunch: lunchImage,
    dinner: dinnerImage,
    buffet: buffetImage,
  };

  //Images from backend
  const backgroundSectionImageStyle = {
    backgroundImage: `url(${menuData?.image_url || defaultSectionImages[menuType]})`,
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    height: '350px',
    backgroundRepeat: 'no-repeat',
  };

  useEffect(() => {
    dispatch({
      type: UPDATE_SECTIONS,
      payload: []
    });
    localStorage.removeItem('addingNewMenu')

    // eslint-disable-next-line
  }, [])

  return (
    <div
      className='col-lg-6 col-md-12 col-sm-12 cursor-pointer override_row_padding'
    >
      <div className={`card menu-card rounded-10 menu_card_main_img`} style={backgroundSectionImageStyle}>
        <div className="d-flex justify-content-between flex-column">
          <div className="menu_card_heading opacity-100 z-index-1">
            <h3 className='text-white fw-600 mb-1'>{menuType?.charAt(0)?.toUpperCase() + menuType?.slice(1)}</h3>
            {(menuData?.items_count) > 0 ?
              <p className='lead text-white fw-normal m-0'>{`${menuData?.items_count} item(s)`}</p>
              :
              <p className='lead text-white fw-normal m-0'>No items found</p>
            }
          </div>
          <div className="menu_card_overlay">
            {!isEmpty(menuData) ? <div>
              <button className='lead fw-600 btn menu_card_action_button' onClick={menuSections}>Edit Menu</button>
            </div> :
              <div>
                <button className='lead fw-600 btn menu_card_action_button' onClick={addMenuSections}>Add Menu</button>
              </div>
            }

            <div className="d-flex justify-content-between align-items-end flex-column w-100 h-100 py-4 pe-4">
              <div>
                <span className='d-flex'>
                  <h5 className='text-white z-index-2 fw-normal'>Menu Completeness: <b>{menuCompletenessPercent}%</b></h5>
                  <div className="menu_card_tooltip ">
                    <h5><i className="fa-regular fa-circle-info ms-2 text-light fw-bold"></i></h5>
                    <span className="menu_card_tooltiptext p-4 d-flex flex-column mb-2">
                      <div className="d-flex">
                        <div>
                          <h5 className='text-left fw-600'>Tagging:</h5>
                          <span className='d-flex align-items-center pt-2'>
                            <div className='progress-bar-size'>
                              <CircularProgressbar value={taggingPercentage} text={taggingProgressBarText}
                                styles={buildStyles(taggingProgressBarStyle)}
                                strokeWidth={12}
                              />
                            </div>
                            <div className="d-flex flex-column">
                              <p className='px-2 text-nowrap text-left fs-16 m-0'>
                                {`${menuDataObjectCheck ? menuData?.with_tagging : 0} Items are tagged.`}
                              </p>
                              <p className='px-2 text-nowrap text-left fs-16 m-0'>
                                {`${menuDataObjectCheck ? menuData?.without_tagging : 0} Items aren’t tagged.`}
                              </p>
                            </div>
                          </span>
                        </div>
                        <div className="pl-2">
                          <h5 className='text-left fw-600'>Images :</h5>
                          <span className='d-flex align-items-center pt-2'>
                            <div className='ml-2 progress-bar-size'>
                              <CircularProgressbar value={imagesPercentage} text={imagesProgressBarText}
                                styles={buildStyles(imageProgressBarStyle)}
                                strokeWidth={12}
                              />
                            </div>
                            <div className="d-flex flex-column">
                              <p className='px-2 text-nowrap text-left fs-16 m-0'>
                                {`${menuDataObjectCheck ? menuData?.uploaded_images : 0} Images are uploaded.`}
                              </p>
                              <p className='px-2 text-nowrap text-left fs-16 m-0'>
                                {`${menuDataObjectCheck ? menuData?.not_uploaded_images : 0} Images aren’t uploaded.`}
                              </p>
                            </div>
                          </span>
                        </div>
                      </div>
                      <div>
                        <p className='w-75 text-left pt-2 mt-1 fs-16 fw-normal m-0'>
                          Menu with all items properly Tagged with uploaded Images will be 100% completed.
                        </p>
                      </div>
                    </span>
                  </div>
                </span>

                <div className="d-flex justify-content-end">
                  <div className='progress-bar-size'>
                    <span
                      data-tip
                      data-for='tagging'
                      className='text-capitalize'>
                      <CircularProgressbar value={taggingPercentage} text={taggingProgressBarText}
                        styles={buildStyles(taggingProgressBarStyle)}
                        strokeWidth={12}
                      />
                    </span>
                    {window.navigator.userAgent.match(/MSIE|Trident/) === null &&  // check if not IE
                      <ReactTooltip
                        type='dark'
                        place='top'
                        effect='solid'
                        className='react-tooltip'
                        id='tagging'
                      >
                        <span>Tagging</span>
                      </ReactTooltip>
                    }
                  </div>
                  <div className='ml-2 progress-bar-size'>
                    <span
                      data-tip
                      data-for='images'
                      className='text-capitalize'>
                      <CircularProgressbar value={imagesPercentage} text={imagesProgressBarText}
                        styles={buildStyles(imageProgressBarStyle)}
                        strokeWidth={12}
                      />
                    </span>
                    {window.navigator.userAgent.match(/MSIE|Trident/) === null &&  // check if not IE
                      <ReactTooltip
                        type='dark'
                        place='top'
                        effect='solid'
                        className='react-tooltip'
                        id='images'
                      >
                        <span>Images</span>
                      </ReactTooltip>
                    }
                  </div>
                </div>

              </div>
              <div className="menu_item_card_items">
                <div className="d-flex align-items-center">
                  {menuData?.dietaries?.splice(0, 3)?.map((dietary: any, index: any) => {
                    return (
                      <span
                        key={index}
                        className='menu_card_ingradient_wrapper d-inline-flex justify-content-center align-items-center me-2'
                        data-tip
                        data-for={`dietary-${index}`} // Use a unique identifier for each tooltip
                      >
                        {dietary?.logo ? (
                          <div className="menu_card_ingradient_wrapper_item_page d-inline-flex justify-content-center align-items-center ml-1">
                            {parse(dietary?.logo)}
                          </div>
                        ) : (
                          <div className="text-uppercase tagging-text">{dietary?.name?.charAt(0)}</div>
                        )}
                        {window.navigator.userAgent.match(/MSIE|Trident/) === null && (
                          <ReactTooltip key={`tooltip-${index}`} type='dark' place='top' effect='solid' className='react-tooltip' id={`dietary-${index}`}>
                            <span>{dietary?.name}</span>
                          </ReactTooltip>
                        )}
                      </span>
                    );
                  })}
                  {
                    menuData?.dietaries?.length > 2 && <span
                      data-tip
                      data-for='dietaries'
                      className='menu_card_ingradient_wrapper d-inline-flex justify-content-center align-items-center me-2 text-capitalize fs-18 text-medium-gray fw-600'
                    >
                      +{menuData?.dietaries?.length - 3}
                      {window.navigator.userAgent.match(/MSIE|Trident/) === null &&  // check if not IE
                        <ReactTooltip type='dark' place='top' effect='solid' className='react-tooltip' id='dietaries'>
                          <span>{menuData?.dietaries?.slice(3)?.map((dietary: any, index: any) => {
                            return <span key={index} >
                              {dietary?.name}&nbsp;
                            </span>
                          })}</span>
                        </ReactTooltip>
                      }
                    </span>
                  }
                </div>
                {menuData?.avg_rating > 0 && <div className='menu_items_card_rating pt-3 d-flex align-items-center' >
                  {[1, 2, 3, 4, 5].map(star =>
                    <div key={star} className='star d-inline-flex ml-2' style={{ width: '20px', height: '20px' }}>
                      <span className='rateValue' style={{ width: `${returnPercentRating(menuData?.avg_rating, star)}` }}></span>
                    </div>
                  )}
                  {menuData?.avg_rating > 0 &&
                    menuData?.avg_rating && <h5 className='ml-3 mb-0 fw-bold text-white'>{`${Number(menuData?.avg_rating)?.toFixed(2)}`}</h5>
                  }
                </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="custom-switch float-right d-flex align-items-center">
      </div>

    </div>
  )
}

export default MenuCard
// import React, { Component, createRef } from 'react';
// import queryString from 'query-string';
// import { connect } from 'react-redux';
// import isEmpty from '../../Utilities/isEmpty';
// import dayjs from 'dayjs';
// import UpdateOrderStatusPopup, { UpdateOrderStatusPopupRef } from '../../Common/Popups/UpdateOrderStatusPopup';
// import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
// import { getSingleOrderDetails, getMeetingPDF, getMeetingLabels } from '../../Actions/orderActions';
// import { showToastNotification } from '../../Common/showToastNotification';
// import { meetingTranslations } from '../../Translations/MeetingTranslations'
// import TableHeader from '../../Common/TableHeader';
// import { OrderDetailsSkelton, OrderDetailsTableSkelton } from './OrderDetailsSkeleton';
// import { downloadFile } from '../../Utilities/DownloadFile';
// import { OrderStatus } from './AllLocationsOrdersListing';
// import '../Settings/restaurant.css'
// import { ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';
// import { Params } from '../Dashboard/Dashboard';
// import { WithTranslation } from 'react-i18next';
// import { GetSingleOrderDetails, OrderRunningMenu } from '../../Interfaces/PayloadsAndResponses/Orders';

// type SingleOrderDetailsProps = {
//   // mapStateToProps
//   single_order_details: GetSingleOrderDetails | null;
//   single_order_details_loading: boolean;
//   i18n: WithTranslation | null;
//   // mapDispatchToProps
//   getMeetingLabels: (id: string | number, meetingId: number, labelDownloadedCallback: () => void) => void;
//   getSingleOrderDetails: (meetingId: number, restaurantId: string | number, queryParams: string, isSorting?: boolean) => void;
//   getMeetingPDF: (id: string | number, meetingId: number, pdfDownloadedCallback: (errMsg?: string) => void) => void;
// } & RouteComponentProps<Params>;

// type SingleOrderDetailsState = {
//   order_confirmed: boolean,
//   sortBy: string,
//   sortType: string,
//   isSorting: boolean,
//   downloadingMeetingPDFSummary: boolean,
//   downloadingMeetingLabel: boolean,
//   order_rejected: boolean
// }

// class SingleOrderDetails extends Component<SingleOrderDetailsProps, SingleOrderDetailsState> {
//   state: SingleOrderDetailsState = {
//     order_confirmed: false,
//     sortBy: '',
//     sortType: '',
//     isSorting: false,
//     downloadingMeetingPDFSummary: false,
//     downloadingMeetingLabel: false,
//     order_rejected: false
//   }
//   updateOrderStatusPopupRef = createRef<UpdateOrderStatusPopupRef>().current;

//   componentDidMount() {
//     meetingTranslations();
//     const { id, restaurantId } = this.props.match.params
//     if (id && restaurantId) {
//       this.props.getSingleOrderDetails(+id, restaurantId, this.props.location.search);
//     }
//   }
//   componentDidUpdate(prevProps: SingleOrderDetailsProps) {
//     if (prevProps.location.search !== this.props.location.search) {
//       const { id, restaurantId } = this.props.match.params
//       if (id && restaurantId) {
//         this.props.getSingleOrderDetails(+id, restaurantId, this.props.location.search, true);
//       }
//     }

//     if (this.props?.single_order_details && JSON.stringify(prevProps?.single_order_details) !== JSON.stringify(this.props?.single_order_details)) {
//       this.setState({
//         sortBy: this.props?.single_order_details?.sort_by,
//         sortType: this.props?.single_order_details?.sort_type,
//         isSorting: false
//       })
//     }
//   }
//   generateQueryParams = () => {
//     let parsedValue: {
//       sort_by?: string,
//       sort_type?: string
//     } = {};
//     const { sortBy, sortType } = this.state;
//     if (sortBy) parsedValue.sort_by = sortBy;
//     if (sortType) parsedValue.sort_type = sortType;

//     let queryURL = !isEmpty(parsedValue) ? `?${queryString.stringify(parsedValue, { skipNull: true, skipEmptyString: true })}` : '';

//     this.props.history.push(`${window.location.pathname}${queryURL}`);
//   }
//   handleSorting = (sortBy: string) => {
//     let sortType = this.state.sortType === 'ASC' ? 'DESC' : 'ASC';
//     this.setState({ sortBy, sortType, isSorting: true }, () => {
//       this.generateQueryParams();
//     });
//   }
//   returnOptions = (options: string[]) => {
//     let optionsString = '';
//     options && options.forEach(option => {
//       optionsString = optionsString + option + (options[options.length - 1] !== option ? ', ' : '');
//     })
//     return optionsString ? optionsString : '';
//   }
//   // acceptOrders = (type: any) => {
//   //   this.props.acceptOrders(type, this.props.match.params.id);
//   //   showToastNotification('Orders Accepted.', 'Success!');
//   //   if (type === 'ack_schedule') {
//   //     this.setState({ acknowledge: true });
//   //     showToastNotification('Thank You', 'Success')
//   //   } else {
//   //     this.setState({ accepted: true });
//   //   }
//   // }
//   meetingConfirmed = () => {
//     this.setState({ order_confirmed: true })
//   }
//   // meetingRejected = () => {
//   //   this.setState({ order_rejected: true });
//   // }
//   // confirmOrderHandler = () => {
//   //   const { match: { params: { restaurantId, id } } } = this.props;
//   //   const { order_confirmed, confirming_order } = this.state;
//   //   if (restaurantId && id && !order_confirmed && !confirming_order) {
//   //     this.modal.openModal(id, restaurantId, null);
//   //   }
//   // }
//   // rejectOrderHandler = () => {
//   //   const { match: { params: { restaurantId, id } } } = this.props;
//   //   // const { order_confirmed, confirming_order } = this.state;
//   //   if (restaurantId && id) {
//   //     this.rejectModal.openModal(id, restaurantId, null);
//   //   }
//   // }
//   capitlizeString = (word: string) => {
//     return word.charAt(0).toUpperCase() + word.slice(1);
//   }
//   // assignDriver = (driver: any) => {
//   //   const driverName = this.capitlizeString(driver.first_name) + ' ' + this.capitlizeString(driver.last_name);
//   //   this.setState({
//   //     driverId: driver.id,
//   //     driverName
//   //   });

//   //   const assignedData = {
//   //     driver_id: driver.id,
//   //     meeting_id: this.props.match.params.id
//   //   };
//   //   this.props.assignDriverToMeeting(assignedData, driverName, this.props.match.params.restaurantId);
//   // }
//   getMeetingPdfSummary = (summary_url: string) => {
//     if (summary_url) {
//       window.open?.(summary_url);
//     } else {
//       const { id, restaurantId } = this.props.match.params;
//       this.setState({ downloadingMeetingPDFSummary: true })
//       if (id && restaurantId) {
//         this.props.getMeetingPDF(restaurantId, +id, (errMsg: string = '') => {
//           this.setState({ downloadingMeetingPDFSummary: false })
//           errMsg && showToastNotification(errMsg, 'Oops!')
//         })
//       }
//     }
//   }
//   // getMeetingExcelSummary = () => {
//   //   const { id, restaurantId } = this.props.match.params;
//   //   this.setState({ downloadingMeetingExcelSummary: true })
//   //   this.props.getMeetingExcelSummary(restaurantId, id, (errMsg: string) => {
//   //     this.setState({ downloadingMeetingExcelSummary: false });
//   //   })
//   // }
//   getMeetingLabel = (runningmenu: OrderRunningMenu) => {
//     const { cutoff_at, labels_url } = runningmenu ?? {};
//     const { id, restaurantId } = this.props.match.params;
//     if (cutoff_at && dayjs(cutoff_at).isAfter(new Date())) {
//       showToastNotification(this.props?.i18n?.t('Print Labels Before Cuttoff') || '', 'Oops!');
//     } else if (labels_url) {
//       downloadFile(labels_url, `Labels(${id ?? '-'}).docx`);
//     } else {
//       if (id && restaurantId) {
//         this.setState({ downloadingMeetingLabel: true })
//         this.props.getMeetingLabels(restaurantId, +id, () => { this.setState({ downloadingMeetingLabel: false }) })
//       }
//     }
//   }
//   render() {
//     const {
//       single_order_details,
//       single_order_details_loading,
//       match,
//     } = this.props;

//     const {
//       runningmenu,
//       orders: ordersList,
//     } = single_order_details || {};

//     const { id } = match.params || {};
//     const { sortBy, isSorting, order_confirmed, order_rejected, downloadingMeetingPDFSummary, downloadingMeetingLabel } = this.state;

//     const columns = [
//       { label: 'Qty.', columnName: 'quantity', sortable: true },
//       { label: 'Order Date', columnName: 'orders.updated_at', sortable: true },
//       { label: 'Item', columnName: 'item', sortable: true },
//       { label: 'Unit Cost' },
//       { label: 'Total' },
//     ];

//     const buffetColumns = [
//       { label: 'Dish size' },
//       { label: 'Order Date', columnName: 'orders.updated_at', sortable: true },
//       { label: 'Serves' },
//       { label: 'Quantity', columnName: 'quantity', sortable: true },
//       { label: 'Item', columnName: 'item', sortable: true },
//       { label: 'Price' },
//       { label: 'Total' },
//     ];

//     const isOrderConfirmed = runningmenu?.orders_accepted || order_confirmed;
//     const isOrderRejected = runningmenu?.rejected_by_vendor || order_rejected;
//     const orderStatus = isOrderConfirmed ? 'Confirmed' : (isOrderRejected ? 'Rejected by vendor' : 'Unconfirmed');
//     const orderDateAndTime = `${dayjs(runningmenu?.delivery_at).format('ddd. MMMM D')} at 
//     ${runningmenu?.delivery_type === 'pickup' ? runningmenu?.pick_up_time : dayjs(runningmenu?.delivery_at).format('hh:mma')}`

//     return (
//       !single_order_details_loading ?
//         <div className='single-order-page'>
//           {!runningmenu ? null :
//             <>
//               <div className='d-flex align-items-center' style={{ gap: 10 }}>
//                 <Link
//                   style={{ color: 'white' }}
//                   className='orders-breadcrumbs'
//                   to={`/dashboard/restaurant/${this.props.match.params.restaurantId}/meetings`}
//                 >
//                   Orders
//                 </Link>
//                 <i style={{ fontSize: 13, color: 'white' }} className='fa-solid fa-angle-right'></i>
//                 <span className='orders-breadcrumbs' >Order # {id}</span>
//               </div>
//               <h3 className='single-order-page-header'>Order Details</h3>
//               <div className='d-flex justify-content-between align-items-center'>
//                 <span style={{ backgroundColor: OrderStatus[orderStatus] }} className='order-status'>
//                   {orderStatus}
//                 </span>
//                 {(isOrderConfirmed || isOrderRejected) ? null :
//                   <button
//                     className='btn accept-order-btn'
//                     onClick={() => this.updateOrderStatusPopupRef?.openModal(orderDateAndTime)}
//                   >
//                     Accept Order
//                   </button>
//                 }
//               </div>
//               <div className='delivery-detail-section mt-4 p-4'>
//                 <div>
//                   <h6>
//                     {dayjs(runningmenu.delivery_at).format('ddd MMM D, ')}
//                     {runningmenu.delivery_type === 'pickup' ?
//                       runningmenu.pick_up_time :
//                       dayjs(runningmenu.delivery_at).format('hh:mma')
//                     }
//                   </h6>
//                   <h6>
//                     {runningmenu.delivery_type === 'pickup' ?
//                       'Pickup Time' :
//                       'Delivery Time'
//                     }
//                   </h6>
//                 </div>
//                 <div>
//                   <h6>{parseFloat(runningmenu.items_count)}</h6>
//                   <h6>Count</h6>
//                 </div>
//                 <div>
//                   <h6 className='text-capitalize'>{runningmenu.menu_type} ({runningmenu.style})</h6>
//                   <h6>Type</h6>
//                 </div>
//                 <div>
//                   <h6>
//                     {dayjs(runningmenu.cutoff_at).format('ddd. MMM D, h:ma')}
//                   </h6>
//                   <h6>Cut-off</h6>
//                 </div>
//               </div>
//               <div className='contact-chowmill'>
//                 <h5>For any questions on items contact&nbsp;
//                   <a href='mailto:support@chowmill.com'>order@chowmill.com</a>
//                 </h5>
//               </div>
//               <div className='delivery-orders-detail-section'>
//                 <div className='d-flex align-items-center justify-content-between'>
//                   <h4 className='m-0' style={{ fontWeight: 600, fontSize: '24px' }}>Items Details</h4>
//                   {parseFloat(runningmenu?.items_count) < 0 ? null :
//                     <div className='download-btns'>
//                       <button
//                         className='btn'
//                         style={{ minWidth: 206 }}
//                         disabled={downloadingMeetingPDFSummary}
//                         onClick={() => this.getMeetingPdfSummary(runningmenu.summary_url ?? '')}
//                       >
//                         <i className='fa-solid fa-list-ul mr-2'></i>
//                         {downloadingMeetingPDFSummary ? 'Downloading Summary...' : 'Download Summary (PDF)'}
//                       </button>
//                       {/* <button
//                         className='btn'
//                         style={{ minWidth: 206 }}
//                         disabled={downloadingMeetingPDFSummary}
//                         onClick={() => this.getMeetingPdfSummary(runningmenu.summary_url ?? '')}
//                       >
//                         <i className='fa-solid fa-list-ul mr-2'></i>
//                         <i className='fas fa-file-pdf mr-2'></i>
//                         {downloadingMeetingPDFSummary ? 'Downloading Summary...' : 'Download Orders (Excel)'}
//                       </button> */}
//                       <button
//                         className='btn d-none'
//                         style={{ minWidth: 119 }}
//                         disabled={downloadingMeetingLabel}
//                         onClick={() => this.getMeetingLabel(runningmenu)}
//                       >
//                         <i className='fa-solid fa-print mr-2'></i>
//                         {downloadingMeetingLabel ? 'Printing...' : 'Print Labels'}

//                       </button>
//                     </div>}
//                 </div>
//               </div>
//               <div className='table-responsive w-100 mt-3 bg-white single-order-table-container'>
//                 <table className='table single-order-table'>
//                   <TableHeader
//                     columns={runningmenu.style === 'buffet' ? buffetColumns : columns}
//                     sortingColumn={sortBy}
//                     handleSorting={this.handleSorting}
//                     inlineHeader={true}
//                   />
//                   <tbody>
//                     {isSorting ? <tr>
//                       <td style={{ height: '100px' }} colSpan={columns.length}>
//                         <div className='d-flex justify-content-center'>
//                           <OrderDetailsTableSkelton />
//                         </div>
//                       </td>
//                     </tr> :
//                       ordersList && ordersList.map((order, index) => (
//                         <tr key={index}>
//                           {runningmenu.style === 'buffet' ? <td className={order.status === 'cancelled' ? 'cancelled-affect' : ''} >
//                             {order.dish_size}
//                           </td> : ''}
//                           {runningmenu.style === 'buffet' ? <td className={order.status === 'cancelled' ? 'cancelled-affect' : ''}>
//                             {dayjs(order.updated_at).format('MM/DD/YYYY hh:mm a')}
//                           </td> : ''}
//                           {runningmenu.style === 'buffet' ? <td className={order.status === 'cancelled' ? 'cancelled-affect' : ''}>
//                             {order.serves}
//                           </td> : ''}
//                           <td className={`py-1 ${order.status === 'cancelled' ? 'cancelled-affect' : ''}`}>
//                             {order.quantity}
//                           </td>
//                           {runningmenu.style === 'individual' ? <td className={order.status === 'cancelled' ? 'cancelled-affect' : ''}>
//                             {dayjs(order.updated_at).format('MM/DD/YYYY hh:mm a')}
//                           </td> : ''}
//                           <td style={{ maxWidth: '400px' }}>
//                             <p className={order.status === 'cancelled' ? 'cancelled-affect' : ''}>
//                               <b>{order.item.name && order.item.name} </b>
//                               {order.user_name && `for ${order.user_name}`}
//                             </p>
//                             <p className={`text-capitalize ${order.status === 'cancelled' ? 'cancelled-affect' : ''}`}>
//                               {order.item.description && order.item.description}
//                             </p>
//                             {order.item.options &&
//                               <p className={order.status === 'cancelled' ? 'cancelled-affect' : ''}>
//                                 <b className='text-info'>Options: </b>
//                                 {this.returnOptions(order.item.options)}
//                               </p>
//                             }
//                             <p className={`text-warning text-wrapper ${order.status === 'cancelled' ? 'cancelled-affect' : ''}`}>
//                               {order.item.remarks}
//                             </p>
//                           </td>
//                           {runningmenu.style === 'buffet' ? <td className={order.status === 'cancelled' ? 'cancelled-affect' : ''}>
//                             {order.price}
//                           </td> : ''}
//                           {runningmenu.style !== 'buffet' ? <td className={order.status === 'cancelled' ? 'cancelled-affect' : ''}>
//                             ${Number(order.price)?.toFixed(2)}
//                           </td> : ''}
//                           <td className={order.status === 'cancelled' ? 'cancelled-affect' : ''}>
//                             ${Number(order.total_price)?.toFixed(2)}
//                           </td>
//                         </tr>
//                       ))}
//                   </tbody>
//                 </table>
//                 <hr />
//                 {runningmenu &&
//                   <div className='container-fluid mt-4 delivery-prices-details'>
//                     <div className='mb-2'>
//                       <p>Count:</p>
//                       <p>{parseFloat(runningmenu.items_count)}</p>
//                     </div>
//                     <div className='mb-2'>
//                       <p>Sub-Total Amount:</p>
//                       <p>${runningmenu.sub_total}</p>
//                     </div>
//                     <div className='mb-2'>
//                       <p>Tax ({runningmenu.tax_percentage}%):</p>
//                       <p>${runningmenu.tax}</p>
//                     </div>
//                     <div className='mb-2'>
//                       {runningmenu.delivery_type === 'delivery' && runningmenu.enable_self_service ?
//                         <>
//                           <p>Delivery Cost:</p>
//                           <p>${runningmenu?.delivery_cost}</p>
//                         </> :
//                         <>
//                           <p>Commission:</p>
//                           <p>${runningmenu?.commission}</p>
//                         </>
//                       }
//                     </div>
//                     <div className='mt-3 mb-2'>
//                       <h4>Total Due:</h4>
//                       <h4>${runningmenu.total_payout}</h4>
//                     </div>
//                   </div>
//                 }
//               </div>
//             </>
//           }
//           <UpdateOrderStatusPopup
//             onRef={(ref: UpdateOrderStatusPopupRef | null) => this.updateOrderStatusPopupRef = ref}
//             meetingConfirmedCallback={this.meetingConfirmed}
//           />
//         </div> :
//         <OrderDetailsSkelton />
//     )
//   }
// }

// const mapStateToProps = (state: ReduxRootState) => ({
//   single_order_details: state.restaurant_data.single_order_details,
//   single_order_details_loading: state.restaurant_data.single_order_details_loading,
//   i18n: state.i18n.i18n
// })

// export default connect(mapStateToProps, { getSingleOrderDetails, getMeetingPDF, getMeetingLabels })(withRouter(SingleOrderDetails));

import React, { useEffect, useRef, useState } from 'react';
import queryString from 'query-string';
import isEmpty from '../../Utilities/isEmpty';
import dayjs from 'dayjs';
import UpdateOrderStatusPopup, { UpdateOrderStatusPopupRef } from '../../Common/Popups/UpdateOrderStatusPopup';
// import { useHistory } from 'react-router-dom'; // Old import (React Router v5)
import {  Link, useParams, useLocation, useNavigate } from 'react-router-dom'; // Replacing useHistory with useNavigate of v6
import { getSingleOrderDetails, getMeetingPDF, getMeetingLabels } from '../../Actions/orderActions';
import { showToastNotification } from '../../Common/showToastNotification';
import { meetingTranslations } from '../../Translations/MeetingTranslations'
import TableHeader from '../../Common/TableHeader';
import { OrderDetailsSkelton, OrderDetailsTableSkelton } from './OrderDetailsSkeleton';
// import { downloadFile } from '../../Utilities/DownloadFile';
import { OrderStatus } from './AllLocationsOrdersListing';
import '../Settings/restaurant.css'
import { ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';
import { Params } from '../Dashboard/Dashboard';
import { OrderRunningMenu } from '../../Interfaces/PayloadsAndResponses/Orders';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getOrderTimeZone } from '../../Utilities/getZonalTime';


const SingleOrderDetails = () => {
  
  const single_order_details = useSelector((state: ReduxRootState) => state?.restaurant_data?.single_order_details);
  const single_order_details_loading = useSelector((state: ReduxRootState) => state?.restaurant_data?.single_order_details_loading);
  const i18n = useSelector((state: ReduxRootState) => state?.i18n?.i18n);
  
  const [orderConfirmed, setOrderConfirmed] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<string>('');
  const [sortType, setSortType] = useState<string>('');
  const [isSorting, setIsSorting] = useState<boolean>(false);
  const [downloadingMeetingPDFSummary, setDownloadingMeetingPDFSummary] = useState<boolean>(false);
  const [downloadingMeetingLabel, setDownloadingMeetingLabel] = useState<boolean>(false);
  const orderRejected: boolean = false;

  const params = useParams<Params>();
  const dispatch: any = useDispatch();
  const location = useLocation();
    // Old hook usage (React Router v5)
  // const history = useHistory();

  // New hook usage (React Router v6)
  const navigate = useNavigate(); // Replacing useHistory with useNavigate
  const updateOrderStatusPopupRef = useRef<UpdateOrderStatusPopupRef | null>(null);

  const { id, restaurantId } = params;

  useEffect(() => {
    meetingTranslations();
    if (id && restaurantId) {
      dispatch(getSingleOrderDetails(+id, restaurantId, location?.search));
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (id && restaurantId) {
      dispatch(getSingleOrderDetails(+id, restaurantId, location?.search))
    }
    //eslint-disable-next-line
  }, [location?.search]);

  useEffect(() => {
    if (single_order_details) {
      setSortBy(single_order_details?.sort_by);
      setSortType(single_order_details?.sort_type);
    }
  }, [single_order_details]);

  const generateQueryParams = () => {
    let parsedValue: {
      sort_by?: string,
      sort_type?: string
    } = {};
    if (sortBy) parsedValue.sort_by = sortBy;
    if (sortType) parsedValue.sort_type = sortType;

    let queryURL = !isEmpty(parsedValue) ? `?${queryString.stringify(parsedValue, { skipNull: true, skipEmptyString: true })}` : '';

    // Old implementation using React Router v5
    // history.push(`${window.location.pathname}${queryURL}`);

    // New implementation using React Router v6
    navigate(`${window.location.pathname}${queryURL}`);
  };
  
  useEffect(() => {
    if (isSorting) {
      generateQueryParams();
    }
    //eslint-disable-next-line 
  }, [isSorting]);

  const handleSorting = (_sortBy: string) => {
    let update_sortType = sortType === 'ASC' ? 'DESC' : 'ASC';
    setSortBy(_sortBy);
    setSortType(update_sortType);
    setIsSorting(true);
  };

  const returnOptions = (options: string[]) => {
    let optionsString = '';
    options && options?.forEach(option => {
      optionsString = optionsString + option + (options[options?.length - 1] !== option ? ', ' : '');
    })
    return optionsString ? optionsString : '';
  };

  // acceptOrders = (type: any) => {
  //   this.props.acceptOrders(type, this.props.match.params.id);
  //   showToastNotification('Orders Accepted.', 'Success!');
  //   if (type === 'ack_schedule') {
  //     this.setState({ acknowledge: true });
  //     showToastNotification('Thank You', 'Success')
  //   } else {
  //     this.setState({ accepted: true });
  //   }
  // }

  const meetingConfirmed = () => {
    setOrderConfirmed(true)
  };

  // meetingRejected = () => {
  //   this.setState({ order_rejected: true });
  // }
  // confirmOrderHandler = () => {
  //   const { match: { params: { restaurantId, id } } } = this.props;
  //   const { order_confirmed, confirming_order } = this.state;
  //   if (restaurantId && id && !order_confirmed && !confirming_order) {
  //     this.modal.openModal(id, restaurantId, null);
  //   }
  // }
  // rejectOrderHandler = () => {
  //   const { match: { params: { restaurantId, id } } } = this.props;
  //   // const { order_confirmed, confirming_order } = this.state;
  //   if (restaurantId && id) {
  //     this.rejectModal.openModal(id, restaurantId, null);
  //   }
  // }
  // const capitlizeString = (word: string) => {
  //   return word.charAt(0).toUpperCase() + word.slice(1);
  // };

  // assignDriver = (driver: any) => {
  //   const driverName = this.capitlizeString(driver.first_name) + ' ' + this.capitlizeString(driver.last_name);
  //   this.setState({
  //     driverId: driver.id,
  //     driverName
  //   });

  //   const assignedData = {
  //     driver_id: driver.id,
  //     meeting_id: this.props.match.params.id
  //   };
  //   this.props.assignDriverToMeeting(assignedData, driverName, this.props.match.params.restaurantId);
  // }
  const getMeetingPdfSummary = (summary_url: string) => {
    //Removed Summary URL S3 logic
    // if (summary_url) {
    //   window.open?.(summary_url);
    // } else {
    // }
    setDownloadingMeetingPDFSummary(true);
    if (id && restaurantId) {
      dispatch(getMeetingPDF(restaurantId, +id, (errMsg: string = '') => {
        setDownloadingMeetingPDFSummary(false);
        errMsg && showToastNotification(errMsg, 'Oops!')
      }))
    }
  }
  // getMeetingExcelSummary = () => {
  //   const { id, restaurantId } = this.props.match.params;
  //   this.setState({ downloadingMeetingExcelSummary: true })
  //   this.props.getMeetingExcelSummary(restaurantId, id, (errMsg: string) => {
  //     this.setState({ downloadingMeetingExcelSummary: false });
  //   })
  // }

  const cancelledMeetingDownloads = () => {
    showToastNotification('This meeting is cancelled', 'Oops!');
  }

  const getMeetingLabel = (runningmenu: OrderRunningMenu) => {
    const { cutoff_at } = runningmenu ?? {};
    if (cutoff_at && dayjs(cutoff_at).isAfter(new Date())) {
      showToastNotification(i18n?.t('Print Labels Before Cuttoff') || '', 'Oops!');
    } else {
      if (id && restaurantId) {
        setDownloadingMeetingLabel(true);
       dispatch(getMeetingLabels(restaurantId, +id, () => { setDownloadingMeetingLabel(false); }))
      }
    }
    // Removed the S3 Urls for downloading Lables
    // else if (labels_url) {
    //   downloadFile(labels_url, `Labels(${id ?? '-'}).docx`);
    // }
  }
  const {
    runningmenu,
    orders: ordersList,
    utensils_total,
  } = single_order_details || {};

  const columns = [
    { label: 'Qty.', columnName: 'quantity', sortable: true },
    { label: 'Order Date', columnName: 'orders.updated_at', sortable: true },
    { label: 'Item', columnName: 'item', sortable: true },
    { label: 'Unit Cost' },
    { label: 'Total' },
  ];

  const buffetColumns = [
    { label: 'Dish size' },
    { label: 'Order Date', columnName: 'orders.updated_at', sortable: true },
    { label: 'Serves' },
    { label: 'Quantity', columnName: 'quantity', sortable: true },
    { label: 'Item', columnName: 'item', sortable: true },
    { label: 'Price' },
    { label: 'Total' },
  ];

  const isOrderConfirmed = runningmenu?.orders_accepted || orderConfirmed;
  const isOrderRejected = runningmenu?.rejected_by_vendor || orderRejected;
  const isCancelledMeeting = runningmenu?.status === 'cancelled' ? true : false;

  const orderStatus = isCancelledMeeting ? 'Cancelled' : (isOrderConfirmed ? 'Confirmed' : (isOrderRejected ? 'Rejected by vendor' : 'Unconfirmed'))
  const orderDateAndTime: any = runningmenu?.delivery_type === 'pickup' ? getOrderTimeZone(runningmenu?.pick_up_time,'E MMMM d, hh:mma, zzzz', runningmenu?.time_zone, 'insertAtInFormat') :  (runningmenu?.delivery_at && getOrderTimeZone(runningmenu?.delivery_at,'E MMMM d, hh:mma, zzzz', runningmenu?.time_zone, 'insertAtInFormat'))


  return (
    !single_order_details_loading ?
      <div className='single-order-page'>
        {!runningmenu ? null :
          <>
            <div className='d-flex align-items-center' style={{ gap: 10 }}>
              <Link
                style={{ color: 'white' }}
                className='orders-breadcrumbs'
                to={`/dashboard/restaurant/${restaurantId}/meetings`}
              >
                Orders
              </Link>
              <i style={{ fontSize: 13, color: 'white' }} className='fa-solid fa-angle-right'></i>
              <span className='orders-breadcrumbs' >Order # {id}</span>
            </div>
            <h3 className='single-order-page-header'>Order Details</h3>
            <div className='d-flex justify-content-between align-items-center'>
              <span style={{ backgroundColor: OrderStatus[orderStatus] }} className='order-status'>
                {orderStatus}
              </span>
              {(isCancelledMeeting || isOrderConfirmed || isOrderRejected) ? null :
                <button
                  className='btn accept-order-btn'
                  onClick={() => updateOrderStatusPopupRef.current?.openModal(orderDateAndTime)}
                >
                  Accept Order
                </button>
              }
            </div>
            <div className='delivery-detail-section mt-4 p-4'>
              <div>
                <h6>
                  {getOrderTimeZone(runningmenu?.pick_up_time, 'EEE. MMM dd h:mma, zzzz', runningmenu?.time_zone)}
                </h6>
                <h6>
                  {runningmenu?.delivery_type === 'pickup' ?
                    'Pickup Time' :
                    'Delivery Time'
                  }
                </h6>
              </div>
              <div>
                <h6>{parseFloat(runningmenu?.items_count)}</h6>
                <h6>Count</h6>
              </div>
              <div>
                <h6>{utensils_total}</h6>
                <h6>Utensils</h6>
              </div>
              <div>
                <h6 className='text-capitalize'>{runningmenu?.menu_type} ({runningmenu?.style})</h6>
                <h6>Type</h6>
              </div>
              <div>
                <h6>
                  {getOrderTimeZone(runningmenu?.same_day ? (runningmenu?.sd_cutoff_at ?? '') : runningmenu?.cutoff_at, 'EEE. MMM dd h:mma, zzzz', runningmenu?.time_zone)}
                </h6>
                <h6>Cut-off</h6>
              </div>
            </div>
            <div className='contact-chowmill'>
              <h5>For any questions on items contact&nbsp;
                <a href='mailto:support@chowmill.com'>order@chowmill.com</a>
              </h5>
            </div>
            <div className='delivery-orders-detail-section'>
              <div className='d-flex align-items-center justify-content-between'>
                <h4 className='m-0' style={{ fontWeight: 600, fontSize: '24px' }}>Items Details</h4>
                {(parseFloat(runningmenu?.items_count) < 0 || isCancelledMeeting) ? <div className='download-btns'>
                  <button
                    className='btn'
                    style={{ minWidth: 206 }}
                    onClick={() => cancelledMeetingDownloads()}
                  >
                    <i className='fa-solid fa-list-ul mr-2'></i>
                    {downloadingMeetingPDFSummary ? 'Downloading Summary...' : 'Download Summary (PDF)'}
                  </button>
                  <button
                    className='btn'
                    style={{ minWidth: 119 }}
                    onClick={() => cancelledMeetingDownloads()}
                  >
                    <i className='fa-solid fa-print mr-2'></i>
                    {downloadingMeetingLabel ? 'Printing...' : 'Print Labels'}
                  </button>
                </div>
                  :
                  <div className='download-btns'>
                    <button
                      className='btn'
                      style={{ minWidth: 206 }}
                      disabled={downloadingMeetingPDFSummary}
                      onClick={() => getMeetingPdfSummary(runningmenu?.summary_url ?? '')}
                    >
                      <i className='fa-solid fa-list-ul mr-2'></i>
                      {downloadingMeetingPDFSummary ? 'Downloading Summary...' : 'Download Summary (PDF)'}
                    </button>
                    {/* <button
                      className='btn'
                      style={{ minWidth: 206 }}
                      disabled={downloadingMeetingPDFSummary}
                      onClick={() => this.getMeetingPdfSummary(runningmenu.summary_url ?? '')}
                    >
                      <i className='fa-solid fa-list-ul mr-2'></i>
                      <i className='fas fa-file-pdf mr-2'></i>
                      {downloadingMeetingPDFSummary ? 'Downloading Summary...' : 'Download Orders (Excel)'}
                    </button> */}
                    <button
                      className='btn'
                      style={{ minWidth: 119 }}
                      disabled={downloadingMeetingLabel}
                      onClick={() => getMeetingLabel(runningmenu)}
                    >
                      <i className='fa-solid fa-print mr-2'></i>
                      {downloadingMeetingLabel ? 'Printing...' : 'Print Labels'}

                    </button>
                  </div>}
              </div>
            </div>
            <div className='table-responsive w-100 mt-3 bg-white single-order-table-container'>
              <table className='table single-order-table'>
                <TableHeader
                  columns={runningmenu?.style === 'buffet' ? buffetColumns : columns}
                  sortingColumn={sortBy}
                  handleSorting={handleSorting}
                  inlineHeader={true}
                />
                <tbody>
                  {isSorting ? <tr>
                    <td style={{ height: '100px' }} colSpan={columns?.length}>
                      <div className='d-flex justify-content-center'>
                        <OrderDetailsTableSkelton />
                      </div>
                    </td>
                  </tr> :
                    ordersList && ordersList?.map((order, index) => (
                      <tr key={index}>
                        {runningmenu?.style === 'buffet' ? <td className={order?.status === 'cancelled' ? 'cancelled-affect' : ''} >
                          {order?.dish_size}
                        </td> : ''}
                        {runningmenu?.style === 'buffet' ? <td className={order?.status === 'cancelled' ? 'cancelled-affect' : ''}>
                          {getOrderTimeZone(order?.updated_at, 'MM/dd/yyyy h:mma, zzzz', runningmenu?.time_zone)}
                        </td> : ''}
                        {runningmenu?.style === 'buffet' ? <td className={order?.status === 'cancelled' ? 'cancelled-affect' : ''}>
                          {order?.serves}
                        </td> : ''}
                        <td className={`py-1 ${order?.status === 'cancelled' ? 'cancelled-affect' : ''}`}>
                          {order?.quantity}
                        </td>
                        {runningmenu?.style === 'individual' ? <td className={order?.status === 'cancelled' ? 'cancelled-affect' : ''}>
                          {getOrderTimeZone(order?.updated_at, 'MM/dd/yyyy h:mma, zzzz', runningmenu?.time_zone)}
                        </td> : ''}
                        <td style={{ maxWidth: '400px' }}>
                          <p className={order?.status === 'cancelled' ? 'cancelled-affect' : ''}>
                            <b>{order?.item?.name && order?.item?.name} </b>
                            {order?.user_name && `for ${order?.user_name}`}
                          </p>
                          <p className={`text-capitalize ${order.status === 'cancelled' ? 'cancelled-affect' : ''}`}>
                            {order?.item?.description && order?.item?.description}
                          </p>
                          {order?.item?.options &&
                            <p className={order?.status === 'cancelled' ? 'cancelled-affect' : ''}>
                              <b className='text-info'>Options: </b>
                              {returnOptions(order?.item?.options)}
                            </p>
                          }
                          <p className={`text-warning text-wrapper ${order?.status === 'cancelled' ? 'cancelled-affect' : ''}`}>
                            {order?.item?.remarks}
                          </p>
                        </td>
                        {runningmenu.style === 'buffet' ? <td className={order?.status === 'cancelled' ? 'cancelled-affect' : ''}>
                          {order?.price}
                        </td> : ''}
                        {runningmenu.style !== 'buffet' ? <td className={order?.status === 'cancelled' ? 'cancelled-affect' : ''}>
                          ${Number(order?.price)?.toFixed(2)}
                        </td> : ''}
                        <td className={order?.status === 'cancelled' ? 'cancelled-affect' : ''}>
                          ${Number(order?.total_price)?.toFixed(2)}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <hr />
              {runningmenu &&
                <div className='container-fluid mt-4 delivery-prices-details'>
                  <div className='mb-2'>
                    <p>Count:</p>
                    <p>{parseFloat(runningmenu?.items_count)}</p>
                  </div>
                  <div className='mb-2'>
                    <p>Sub-Total Amount:</p>
                    <p>${runningmenu?.sub_total}</p>
                  </div>
                  <div className='mb-2'>
                    <p>Tax ({runningmenu?.tax_percentage}%):</p>
                    <p>${runningmenu?.tax}</p>
                  </div>
                  <div className='mb-2'>
                    {runningmenu?.delivery_type === 'delivery' && runningmenu?.enable_self_service ?
                      <>
                        <p>Delivery Cost:</p>
                        <p>${runningmenu?.delivery_cost}</p>
                      </> :
                      <>
                        <p>Commission:</p>
                        <p>${runningmenu?.commission}</p>
                      </>
                    }
                  </div>
                  <div className='mt-3 mb-2'>
                    <h4>Total Due:</h4>
                    <h4>${runningmenu?.total_payout}</h4>
                  </div>
                </div>
              }
            </div>
          </>
        }
        <UpdateOrderStatusPopup
          onRef={(ref: UpdateOrderStatusPopupRef | null) => (updateOrderStatusPopupRef.current = ref)}
          meetingConfirmedCallback={meetingConfirmed}
        />
      </div> :
      <OrderDetailsSkelton />
  )
}
export default SingleOrderDetails;
// import React, { Component } from 'react';
// import { withRouter } from 'react-router-dom';
// import Spinner from '../../Common/Spinner';
// import { RouteComponentProps } from 'react-router';
// import { connect } from 'react-redux';
// import { getInvitedUserForSignup } from '../../Actions/signupInvitationActions'
// import { GetInvitedUserForSignup } from '../../Interfaces/PayloadsAndResponses/SignupInvitation';

// type RouteParams = { inviteToken: string }

// type InvitePageProps = {
//   getInvitedUserForSignup: (inviteToken: string, redirectToLoginOrSignupPage: (response: GetInvitedUserForSignup) => void) => void,
// } & RouteComponentProps<RouteParams>;

// class InvitePage extends Component<InvitePageProps, {}>  {

//   redirectToLoginOrSignupPage = (response: GetInvitedUserForSignup) => {
//     const { success, data } = response || {};
//     if (success === true && data) {
//       window.location.href = '/signup'
//     } else {
//       localStorage.clear();
//       window.location.href = '/'
//     }
//   }

//   componentDidMount() {
//     localStorage.clear();
//     this.props.getInvitedUserForSignup(this.props.match.params.inviteToken, this.redirectToLoginOrSignupPage);
//   }

//   render() {

//     return (
//       <div className='h-100 w-100 d-flex justify-content-center align-items-center'>
//         <Spinner />
//       </div>
//     )
//   }
// }

// export default connect(null, { getInvitedUserForSignup })(withRouter(InvitePage));


import React, { FC, useEffect } from 'react';
// import { useParams } from 'react-router-dom'; // React Router v5
import { useNavigate, useParams } from 'react-router-dom'; // React Router v6
import { useDispatch } from 'react-redux';
import Spinner from '../../Common/Spinner';
import { getInvitedUserForSignup } from '../../Actions/signupInvitationActions';
import { GetInvitedUserForSignup } from '../../Interfaces/PayloadsAndResponses/SignupInvitation';

type RouteParams = { inviteToken: string };

const InvitePage: FC = () => {
  const dispatch = useDispatch<any>();
  const { inviteToken } = useParams<RouteParams>();
  const navigate = useNavigate(); // New React Router v6 hook

  const redirectToLoginOrSignupPage = (response: GetInvitedUserForSignup) => {
    const { success, data } = response || {};
    if (success === true && data) {
      // window.location.href = '/signup'; // Old way (React Router v5)
      navigate('/signup'); // New way (React Router v6)
    } else {
      localStorage.clear();
      // window.location.href = '/'; // Old way (React Router v5)
      navigate('/'); // New way (React Router v6)
    }
  };

  useEffect(() => {
    localStorage.clear();
    inviteToken && dispatch(getInvitedUserForSignup(inviteToken, redirectToLoginOrSignupPage));
      //eslint-disable-next-line
  }, [dispatch, inviteToken ]);

  return (
    <div className='h-100 w-100 d-flex justify-content-center align-items-center'>
      <Spinner />
    </div>
  );
};

export default InvitePage;
// import React from 'react';
// import { resetPassword } from '../../Actions/authActions';
// import ButtonSpinner from '../../Common/ButtonSpinner';
// import { connect } from 'react-redux';
// import './password.css'
// import { AuthReducer, ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState'; import { RouteComponentProps } from 'react-router';
// ;

// type ResetPasswordProps = {
//   auth: AuthReducer
// } & RouteComponentProps;

// type ResetPasswordState = {
//   password: string,
//   passwordAgain: string,
//   showLoading: boolean,
// }

// class ResetPassword extends React.Component<ResetPasswordProps, ResetPasswordState> {

//   state = {
//     password: '',
//     passwordAgain: '',
//     showLoading: false
//   }

//   componentDidMount() {
//     let document = window.document;
//     var i = Math.floor((Math.random() * 9) + 1);
//     if (document.getElementById('banner')?.style) {
//       document.getElementById('banner')!.style.backgroundImage = 'url(imgs/' + i + '.jpg)'
//     }
//   }
//   componentWillReceiveProps(nextProps: ResetPasswordProps) {
//     if (nextProps.auth && nextProps.auth.reset_password_status) {
//       if (nextProps.auth.reset_password_status.success === true) {
//         this.props.history.push('/', { resetMessage: nextProps.auth.reset_password_status.message })
//         this.setState({ showLoading: false })
//       }
//     }
//   }
//   onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const { target: { name, value } } = e;
//     e.preventDefault();
//     this.setState(prevState => ({ ...prevState, [name]: value }));
//   }

//   check_constraints() {
//     let document: any = window.document;
//     var test_passed = true;
//     if (this.state.password.length < 6 || this.state.passwordAgain.length < 6) {
//       test_passed = false;
//       if (this.state.password.length < 6) {
//         document.getElementById('errorr_1').hidden = false;
//         document.getElementById('errorr_2').hidden = true;
//       } else {
//         document.getElementById('errorr_1').hidden = true;
//         document.getElementById('errorr_2').hidden = false;
//       }
//     } else {
//       document.getElementById('errorr_1').hidden = true;
//       document.getElementById('errorr_2').hidden = true;
//     }
//     if (this.state.password.length > 5 && this.state.passwordAgain.length > 5 && this.state.password !== this.state.passwordAgain) {
//       test_passed = false;
//       document.getElementById('errorr_3').hidden = false;
//     } else {
//       document.getElementById('errorr_3').hidden = true;
//     }
//     return test_passed;
//   }
//   onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
//     e.preventDefault();
//     const test = this.check_constraints();
//     if (test === true) {
//       let accessToken = window.location.href.split('?')[1] && window.location.href.split('?')[1].split('&')[0].split('=')[1];
//       let client = window.location.href.split('?')[1] && window.location.href.split('?')[1].split('&')[1].split('=')[1];
//       let uid = window.location.href.split('?')[1] && window.location.href.split('?')[1].split('&')[7].split('=')[1];
//       //@ts-ignore
//       this.props.resetPassword(e.target.password.value, e.target.passwordAgain.value, accessToken, client, decodeURIComponent(uid));
//       this.setState({ showLoading: true })
//     }
//   }
//   render() {
//     return (
//       <div>
//         <div className='container-scroller'>
//           <div className='container-fluid page-body-wrapper full-page-wrapper'>
//             <div className='content-wrapper d-flex align-items-stretch auth auth-img-bg'>
//               <div className='row flex-grow'>
//                 <div className='col-lg-6 d-flex align-items-center justify-content-center'>
//                   <div className='auth-form-transparent text-left p-3'>
//                     <div className='brand-logo'>
//                       <img src='./imgs/chowmill-logo-full.png' className='logo mt-5 mw-100' alt='logo' />
//                     </div>
//                     <h4 className='mt-5'>Reset Your Password</h4>
//                     <form className='pt-3' noValidate onSubmit={this.onSubmit}>
//                       <div className='mb-4'>
//                         <label htmlFor='inputEmail'>New Password</label>
//                         <div className='input-group'>
//                           <div className='input-group-prepend bg-transparent'>
//                             <span className='input-group-text bg-transparent border-right-0 text-primary'>
//                               <i className='mdi mdi-lock-outline text-primary'></i>
//                             </span>
//                           </div>
//                           <input type='password' id='inputPassword' className='form-control form-control-lg border-left-0 height-46' name='password' autoFocus onChange={this.onChange} />
//                           <p id='errorr_1' hidden={true} className='invalid-feedback-password'>Please insert at least 6 characters.</p>
//                         </div>
//                       </div>
//                       <br />
//                       <div className='mb-4'>
//                         <label htmlFor='passwordAgain'>Confirm Password</label>
//                         <div className='input-group'>
//                           <div className='input-group-prepend bg-transparent'>
//                             <span className='input-group-text bg-transparent border-right-0 text-primary'>
//                               <i className='mdi mdi-lock-outline text-primary'></i>
//                             </span>
//                           </div>
//                           <input type='password' id='passwordAgain' className='form-control form-control-lg border-left-0 height-46' name='passwordAgain' onChange={this.onChange} />
//                           <p id='errorr_2' hidden={true} className='invalid-feedback-password'>Please insert at least 6 characters.</p>
//                           <p id='errorr_3' hidden={true} className='invalid-feedback-password'>Both passwords must be same.</p>
//                         </div>
//                       </div>
//                       <br />
//                       <button className='btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn' type='submit'> {this.state.showLoading ? <ButtonSpinner /> : <b>Submit</b>} </button>
//                     </form>
//                   </div>
//                 </div>
//                 <div className='col-md-6 d-none d-lg-block login_banner' id='banner'>
//                   <p className='text-white font-weight-medium text-center flex-grow align-self-end copy-rights'>{'© Copyright ' + new Date().getFullYear() + ' Chowmill '}</p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

// const mapStateToProps = (state: ReduxRootState) => ({
//   auth: state.auth
// });

// export default connect(mapStateToProps, { resetPassword })(ResetPassword);


import React, { useState, useEffect, FC } from 'react';
import { resetPassword } from '../../Actions/authActions';
import ButtonSpinner from '../../Common/ButtonSpinner';
import { useSelector, useDispatch } from 'react-redux';
import './password.css';
import { ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';
// import { useHistory } from 'react-router'; // React Router v5
import { useNavigate } from 'react-router-dom'; // React Router v6




const ResetPassword: FC = () => {
  const [password, setPassword] = useState<string>('');
  const [passwordAgain, setPasswordAgain] = useState<string>('');
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const auth = useSelector((state: ReduxRootState) => state.auth);
  const dispatch = useDispatch<any>();
  // const history = useHistory(); // Old React Router v5 hook
  const navigate = useNavigate(); // New React Router v6 hook

  const [isPasswordShow, setIsPasswordShow] = useState<boolean>(false);
  const [isPasswordConfirmShow, setIsPasswordConfirmShow] = useState<boolean>(false);


  useEffect(() => {
    let document = window.document;
    var i = Math.floor((Math.random() * 9) + 1);
    if (document.getElementById('banner')?.style) {
      document.getElementById('banner')!.style.backgroundImage = 'url(imgs/' + i + '.jpg)'
    }
  }, []);

  useEffect(() => {
    if (auth && auth?.reset_password_status && auth?.reset_password_status?.success === true) {
      // history.push('/', { resetMessage: auth?.reset_password_status?.message }); // Old way (React Router v5)
      navigate('/', { state: { resetMessage: auth?.reset_password_status?.message } }); // New way (React Router v6)
      setShowLoading(false);
    }
  }, [auth, navigate]);

  const togglePasswordVisibility = () => {
    setIsPasswordShow(!isPasswordShow);
  };
  const togglePasswordConfirmVisibility = () => {
    setIsPasswordConfirmShow(!isPasswordConfirmShow);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { target: { name, value } } = e;
    e.preventDefault();
    if (name === 'password') {
      setPassword(value);
    } else if (name === 'passwordAgain') {
      setPasswordAgain(value);
    }
  };

  const checkConstraints = () => {
    let document: any = window.document;
    var testPassed = true;
    if (password?.length < 6 || passwordAgain?.length < 6) {
      testPassed = false;
      if (password?.length < 6) {
        document.getElementById('errorr_1').hidden = false;
        document.getElementById('errorr_2').hidden = true;
      } else {
        document.getElementById('errorr_1').hidden = true;
        document.getElementById('errorr_2').hidden = false;
      }
    } else {
      document.getElementById('errorr_1').hidden = true;
      document.getElementById('errorr_2').hidden = true;
    }
    if (password?.length > 5 && passwordAgain?.length > 5 && password !== passwordAgain) {
      testPassed = false;
      document.getElementById('errorr_3').hidden = false;
    } else {
      document.getElementById('errorr_3').hidden = true;
    }
    return testPassed;
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const test = checkConstraints();
    if (test === true) {
      const url = window.location.href
      const urldata = url?.split('?')[1]?.split('&')?.reduce((acc: any, str: any) => {
        const [key, value] = str?.split('=');
        acc[key] = value;
        return acc;
      }, {});
      const { 'access-token': accessToken, client, uid } = urldata;
      //old method of accessing URL data
      // let accessToken = window.location.href.split('?')[1] && window.location.href.split('?')[1].split('&')[0].split('=')[1];
      // let client = window.location.href.split('?')[1] && window.location.href.split('?')[1].split('&')[1].split('=')[1];
      // let uid = window.location.href.split('?')[1] && window.location.href.split('?')[1].split('&')[7].split('=')[1];
      // let isMobile = 'true'
      localStorage.setItem('isMobile', urldata?.app ? urldata?.app : 'false');
      dispatch(resetPassword(password, passwordAgain, accessToken, client, decodeURIComponent(uid)));
      setShowLoading(true);
    }
  }
  return (
    <div>
      <div className='container-scroller'>
        <div className='container-fluid page-body-wrapper full-page-wrapper'>
          <div className='content-wrapper d-flex align-items-stretch auth auth-img-bg'>
            <div className='row flex-grow'>
              <div className='col-lg-6 d-flex align-items-center justify-content-center'>
                <div className='auth-form-transparent text-left p-3'>
                  <div className='brand-logo'>
                    <img src='./imgs/chowmill-logo-full.png' className='logo mt-5 mw-100' alt='logo' />
                  </div>
                  <h4 className='mt-5'>Reset Your Password</h4>
                  <form className='pt-3' noValidate onSubmit={onSubmit}>
                    <div className='mb-4'>
                      <label htmlFor='inputEmail'>New Password</label>
                      <div className='input-group'>
                        <div className='input-group-prepend bg-transparent'>
                          <span className='input-group-text bg-transparent border-right-0 text-primary'>
                            <i className='mdi mdi-lock-outline text-primary'></i>
                          </span>
                        </div>
                        <input type={isPasswordShow ? 'text' : 'password'}
                          id='inputPassword'
                          className='form-control form-control-lg border-left-0 height-46'
                          name='password'
                          autoFocus
                          onChange={onChange}
                        />
                        <div className={'password-icon'} onClick={togglePasswordVisibility}>
                          {isPasswordShow ?
                            <span>
                              <svg xmlns="http:www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M150.7 92.77C195 58.27 251.8 32 320 32C400.8 32 465.5 68.84 512.6 112.6C559.4 156 590.7 207.1 605.5 243.7C608.8 251.6 608.8 260.4 605.5 268.3C592.1 300.6 565.2 346.1 525.6 386.7L630.8 469.1C641.2 477.3 643.1 492.4 634.9 502.8C626.7 513.2 611.6 515.1 601.2 506.9L9.196 42.89C-1.236 34.71-3.065 19.63 5.112 9.196C13.29-1.236 28.37-3.065 38.81 5.112L150.7 92.77zM223.1 149.5L313.4 220.3C317.6 211.8 320 202.2 320 191.1C320 180.5 316.1 169.7 311.6 160.4C314.4 160.1 317.2 159.1 320 159.1C373 159.1 416 202.1 416 255.1C416 269.7 413.1 282.7 407.1 294.5L446.6 324.7C457.7 304.3 464 280.9 464 255.1C464 176.5 399.5 111.1 320 111.1C282.7 111.1 248.6 126.2 223.1 149.5zM320 480C239.2 480 174.5 443.2 127.4 399.4C80.62 355.1 49.34 304 34.46 268.3C31.18 260.4 31.18 251.6 34.46 243.7C44 220.8 60.29 191.2 83.09 161.5L177.4 235.8C176.5 242.4 176 249.1 176 255.1C176 335.5 240.5 400 320 400C338.7 400 356.6 396.4 373 389.9L446.2 447.5C409.9 467.1 367.8 480 320 480H320z" /></svg>
                            </span>
                            :
                            <span>
                              <svg xmlns="http:www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M279.6 160.4C282.4 160.1 285.2 160 288 160C341 160 384 202.1 384 256C384 309 341 352 288 352C234.1 352 192 309 192 256C192 253.2 192.1 250.4 192.4 247.6C201.7 252.1 212.5 256 224 256C259.3 256 288 227.3 288 192C288 180.5 284.1 169.7 279.6 160.4zM480.6 112.6C527.4 156 558.7 207.1 573.5 243.7C576.8 251.6 576.8 260.4 573.5 268.3C558.7 304 527.4 355.1 480.6 399.4C433.5 443.2 368.8 480 288 480C207.2 480 142.5 443.2 95.42 399.4C48.62 355.1 17.34 304 2.461 268.3C-.8205 260.4-.8205 251.6 2.461 243.7C17.34 207.1 48.62 156 95.42 112.6C142.5 68.84 207.2 32 288 32C368.8 32 433.5 68.84 480.6 112.6V112.6zM288 112C208.5 112 144 176.5 144 256C144 335.5 208.5 400 288 400C367.5 400 432 335.5 432 256C432 176.5 367.5 112 288 112z" /></svg>
                            </span>
                          }
                        </div>
                      </div>
                      <p id='errorr_1' hidden={true} className='invalid-feedback-password'>Please insert at least 6 characters.</p>
                    </div>
                    <br />
                    <div className='mb-4'>
                      <label htmlFor='passwordAgain'>Confirm Password</label>
                      <div className='input-group'>
                        <div className='input-group-prepend bg-transparent'>
                          <span className='input-group-text bg-transparent border-right-0 text-primary'>
                            <i className='mdi mdi-lock-outline text-primary'></i>
                          </span>
                        </div>
                        <input type={isPasswordConfirmShow ? 'text' : 'password'}
                          id='passwordAgain'
                          className='form-control form-control-lg border-left-0 height-46'
                          name='passwordAgain'
                          onChange={onChange}
                        />
                        <div className={'password-icon'} onClick={togglePasswordConfirmVisibility}>
                          {isPasswordConfirmShow ?
                            <span>
                              <svg xmlns="http:www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M150.7 92.77C195 58.27 251.8 32 320 32C400.8 32 465.5 68.84 512.6 112.6C559.4 156 590.7 207.1 605.5 243.7C608.8 251.6 608.8 260.4 605.5 268.3C592.1 300.6 565.2 346.1 525.6 386.7L630.8 469.1C641.2 477.3 643.1 492.4 634.9 502.8C626.7 513.2 611.6 515.1 601.2 506.9L9.196 42.89C-1.236 34.71-3.065 19.63 5.112 9.196C13.29-1.236 28.37-3.065 38.81 5.112L150.7 92.77zM223.1 149.5L313.4 220.3C317.6 211.8 320 202.2 320 191.1C320 180.5 316.1 169.7 311.6 160.4C314.4 160.1 317.2 159.1 320 159.1C373 159.1 416 202.1 416 255.1C416 269.7 413.1 282.7 407.1 294.5L446.6 324.7C457.7 304.3 464 280.9 464 255.1C464 176.5 399.5 111.1 320 111.1C282.7 111.1 248.6 126.2 223.1 149.5zM320 480C239.2 480 174.5 443.2 127.4 399.4C80.62 355.1 49.34 304 34.46 268.3C31.18 260.4 31.18 251.6 34.46 243.7C44 220.8 60.29 191.2 83.09 161.5L177.4 235.8C176.5 242.4 176 249.1 176 255.1C176 335.5 240.5 400 320 400C338.7 400 356.6 396.4 373 389.9L446.2 447.5C409.9 467.1 367.8 480 320 480H320z" /></svg>
                            </span>
                            :
                            <span>
                              <svg xmlns="http:www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M279.6 160.4C282.4 160.1 285.2 160 288 160C341 160 384 202.1 384 256C384 309 341 352 288 352C234.1 352 192 309 192 256C192 253.2 192.1 250.4 192.4 247.6C201.7 252.1 212.5 256 224 256C259.3 256 288 227.3 288 192C288 180.5 284.1 169.7 279.6 160.4zM480.6 112.6C527.4 156 558.7 207.1 573.5 243.7C576.8 251.6 576.8 260.4 573.5 268.3C558.7 304 527.4 355.1 480.6 399.4C433.5 443.2 368.8 480 288 480C207.2 480 142.5 443.2 95.42 399.4C48.62 355.1 17.34 304 2.461 268.3C-.8205 260.4-.8205 251.6 2.461 243.7C17.34 207.1 48.62 156 95.42 112.6C142.5 68.84 207.2 32 288 32C368.8 32 433.5 68.84 480.6 112.6V112.6zM288 112C208.5 112 144 176.5 144 256C144 335.5 208.5 400 288 400C367.5 400 432 335.5 432 256C432 176.5 367.5 112 288 112z" /></svg>
                            </span>
                          }
                        </div>
                      </div>
                      <p id='errorr_2' hidden={true} className='invalid-feedback-password'>Please insert at least 6 characters.</p>
                      <p id='errorr_3' hidden={true} className='invalid-feedback-password'>Both passwords must be same.</p>
                    </div>
                    <br />
                    <button className='btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn' type='submit'> {showLoading ? <ButtonSpinner /> : <b>Submit</b>} </button>
                  </form>
                </div>
              </div>
              <div className='col-md-6 d-none d-lg-block login_banner' id='banner'>
                <p className='text-white font-weight-medium text-center flex-grow align-self-end copy-rights'>{'© Copyright ' + new Date().getFullYear() + ' Chowmill '}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword
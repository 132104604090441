import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IframeResizer from 'iframe-resizer-react'
import { getStatisticsReports } from '../../Actions/reportsActions';
// import { useParams } from 'react-router';
import { useParams } from 'react-router-dom'; // React Router v6 update
import { ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';;

const StatisticsReports: FC<{}> = () => {
  const dispatch: any = useDispatch();
  const { restaurantId } = useParams<{ restaurantId: string }>()
  const { statistics_report_url } = useSelector((state: ReduxRootState) => state.reports);

  useEffect(() => {
    restaurantId && dispatch(getStatisticsReports(restaurantId));
    document.title = 'Reports | Chowmill';
  }, [dispatch, restaurantId]);

  return (
    <div className='content-wrapper'>
      <div className='container'>
        <div className='row card reports-title'>
          <div className='card-body'>
            <h2>Reports</h2>
          </div>
          <div className='reports-main row mx-auto px-0'>
            <>
              {statistics_report_url ?
                <IframeResizer
                  heightCalculationMethod='lowestElement'
                  src={statistics_report_url}
                  style={{ minWidth: '100%', border: 0 }}
                  frameBorder={0}
                />
                : ''}
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatisticsReports;

// import React from 'react';
// import { resetPasswordLink } from '../../Actions/authActions';
// import { connect } from 'react-redux';
// import { showToastNotification } from '../../Common/showToastNotification';
// import ButtonSpinner from '../../Common/ButtonSpinner';
// import { authTranslations } from '../../Translations/AuthTranslations'
// import { AuthReducer, Errors, ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';
// import { WithTranslation } from 'react-i18next';

// export type ForgotPasswordProps = {
//   resetPasswordLink: (encoded: string) => void;
//   i18n: WithTranslation | null;
//   auth: AuthReducer;
//   errors: Errors;
// }

// class ForgotPassword extends React.Component<ForgotPasswordProps, { showLoading: boolean }> {

//   state = {
//     showLoading: false
//   }

//   componentDidMount() {
//     let document = window.document;
//     authTranslations();
//     var i = Math.floor((Math.random() * 9) + 1);
//     if (document.getElementById('banner')?.style) {
//       document.getElementById('banner')!.style.backgroundImage = 'url(imgs/' + i + '.jpg)'
//     }
//   }
//   componentWillReceiveProps(nextProps: ForgotPasswordProps) {
//     if (nextProps.auth && nextProps.auth.forgot_mail_status !== this.props.auth.forgot_mail_status) {
//       if (nextProps.auth.forgot_mail_status?.message) {
//         showToastNotification(nextProps.auth.forgot_mail_status.message, 'Status')
//       } else if (nextProps.auth.forgot_mail_status?.message_heading) {
//         showToastNotification(nextProps.auth.forgot_mail_status.message_text ?? '', nextProps.auth.forgot_mail_status.message_heading)
//       }
//       this.setState({ showLoading: false })
//     } else if (nextProps.errors && nextProps.errors.errors !== null) {
//       showToastNotification(nextProps.errors.errors, 'Oops!')
//       this.setState({ showLoading: false })
//     }
//   }
//   onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
//     e.preventDefault();
//     //@ts-ignore
//     var encoded = btoa(e.target.email.value);
//     this.props.resetPasswordLink(encoded);
//     this.setState({ showLoading: true })
//   }
//   render() {
//     return (
//       <React.Fragment>
//         <div>
//           <div id='show-notification' className='float-right'></div>
//           <div className='container-scroller'>
//             <div className='container-fluid page-body-wrapper full-page-wrapper'>
//               <div className='content-wrapper d-flex align-items-stretch auth auth-img-bg'>
//                 <div className='row flex-grow'>
//                   <div className='col-lg-6 d-flex align-items-center justify-content-center'>
//                     <div className='auth-form-transparent text-left p-3'>
//                       <div className='brand-logo'>
//                         <img src='./imgs/chowmill-logo-full.png' className='logo mt-5 mw-100' alt='logo' />
//                       </div>
//                       <h4 className='mt-5'> {this.props.i18n && this.props.i18n.t('Forgot Password')}</h4>
//                       <div id='success_message' hidden={true} className='alert alert-success login-alert' role='alert'></div>
//                       <div id='error_message' hidden={true} className='alert alert-danger login-alert' role='alert'></div>
//                       <form className='pt-3' onSubmit={this.onSubmit}>
//                         <div className='mb-4'>
//                           <label htmlFor='inputEmail'> {this.props.i18n && this.props.i18n.t('Email address')}</label>
//                           <div className='input-group'>
//                             <div className='input-group-prepend bg-transparent'>
//                               <span className='input-group-text bg-transparent border-right-0 text-primary'>
//                                 <i className='mdi mdi-lock-outline text-primary'></i>
//                               </span>
//                             </div>
//                             <input type='email' id='inputEmail' className='form-control form-control-lg border-left-0 height-46' name='email' autoComplete='on' required autoFocus />
//                           </div>
//                         </div>
//                         <br />
//                         <button className='btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn' type='submit'>{this.state.showLoading ? <ButtonSpinner /> : <b>{this.props.i18n && this.props.i18n.t('Submit')}</b>} </button>
//                       </form>
//                     </div>
//                   </div>
//                   <div className='col-md-6 d-none d-lg-block login_banner' id='banner'>
//                     <p className='text-white font-weight-medium text-center flex-grow align-self-end copy-rights'>{'© Copyright ' + new Date().getFullYear() + ' Chowmill '}</p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </React.Fragment>
//     );
//   }
// }
// const mapStateToProps = (state: ReduxRootState) => ({
//   errors: state.errors,
//   auth: state.auth,
//   i18n: state.i18n.i18n
// });

// export default connect(mapStateToProps, { resetPasswordLink })(ForgotPassword);


import React, { useState, useEffect, FC } from 'react';
import { resetPasswordLink } from '../../Actions/authActions';
import { useDispatch, useSelector } from 'react-redux';
import { showToastNotification } from '../../Common/showToastNotification';
import ButtonSpinner from '../../Common/ButtonSpinner';
import { authTranslations } from '../../Translations/AuthTranslations'
import {  ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';

export type ForgotPasswordProps = {
  resetPasswordLink: (encoded: string) => void;
}

const ForgotPassword: FC = () => {
  const dispatch = useDispatch<any>();
  const errors = useSelector((state: ReduxRootState) => state.errors);
  const i18n = useSelector((state: ReduxRootState) => state.i18n.i18n);
  const { forgot_mail_status }  = useSelector((state: ReduxRootState) => state.auth);

  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    let document = window.document;
    authTranslations();
    var i = Math.floor((Math.random() * 9) + 1);
    if (document.getElementById('banner')?.style) {
      document.getElementById('banner')!.style.backgroundImage = 'url(imgs/' + i + '.jpg)'
    }
  }, []);

  useEffect(() => {
    if (forgot_mail_status !== null && forgot_mail_status !== undefined) {
      if (forgot_mail_status?.message) {
        showToastNotification(forgot_mail_status.message, 'Status');
      } else if (forgot_mail_status?.message_heading) {
        showToastNotification(forgot_mail_status.message_text ?? '', forgot_mail_status.message_heading);
      }
      setShowLoading(false);
    } else if (errors.errors !== null) {
      showToastNotification(errors.errors, 'Oops!');
      setShowLoading(false);
    }
  }, [forgot_mail_status, errors]);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    //@ts-ignore
    const encoded = btoa(e.currentTarget.email.value);
    dispatch(resetPasswordLink(encoded));
    setShowLoading(true);
  }  
    return (
      <React.Fragment>
        <div>
          <div id='show-notification' className='float-right'></div>
          <div className='container-scroller'>
            <div className='container-fluid page-body-wrapper full-page-wrapper'>
              <div className='content-wrapper d-flex align-items-stretch auth auth-img-bg'>
                <div className='row flex-grow'>
                  <div className='col-lg-6 d-flex align-items-center justify-content-center'>
                    <div className='auth-form-transparent text-left p-3'>
                      <div className='brand-logo'>
                        <img src='./imgs/chowmill-logo-full.png' className='logo mt-5 mw-100' alt='logo' />
                      </div>
                      <h4 className='mt-5'> {i18n && i18n.t('Forgot Password')}</h4>
                      <div id='success_message' hidden={true} className='alert alert-success login-alert' role='alert'></div>
                      <div id='error_message' hidden={true} className='alert alert-danger login-alert' role='alert'></div>
                      <form className='pt-3' onSubmit={onSubmit}>
                        <div className='mb-4'>
                          <label htmlFor='inputEmail'> {i18n && i18n.t('Email address')}</label>
                          <div className='input-group'>
                            <div className='input-group-prepend bg-transparent'>
                              <span className='input-group-text bg-transparent border-right-0 text-primary'>
                                <i className='mdi mdi-lock-outline text-primary'></i>
                              </span>
                            </div>
                            <input type='email' id='inputEmail' className='form-control form-control-lg border-left-0 height-46' name='email' autoComplete='on' required autoFocus />
                          </div>
                        </div>
                        <br />
                        <button className='btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn' type='submit'>{showLoading ? <ButtonSpinner /> : <b>{i18n && i18n.t('Submit')}</b>} </button>
                      </form>
                    </div>
                  </div>
                  <div className='col-md-6 d-none d-lg-block login_banner' id='banner'>
                    <p className='text-white font-weight-medium text-center flex-grow align-self-end copy-rights'>{'© Copyright ' + new Date().getFullYear() + ' Chowmill '}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
export default ForgotPassword;
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom'; // React Router v6 update
import { useDispatch } from 'react-redux';
import { unlockUserAccount } from '../../Actions/authActions';

const UnlockUserAccount = () => {
  const dispatch: any = useDispatch();
  const { token } = useParams<{ token: string }>();
  // const history = useHistory(); // React Router v5: Old history hook
  const navigate = useNavigate(); // React Router v6: New navigate hook

  useEffect(() => {
    token && dispatch(unlockUserAccount(token, (message: string) => {
      // history.push('/', { resetMessage: message }); // React Router v5: Old push method
      navigate('/', { state: { resetMessage: message } }); // React Router v6: New navigation method
    }));
  }, [dispatch, token, navigate]);

  return (
    <div className='h-100 w-100 d-flex justify-content-center align-items-center'>
      <h3>Unlocking your account...</h3>
    </div>
  );
}

export default UnlockUserAccount;
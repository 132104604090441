import React, { useEffect, useState, useRef } from 'react';
import { getSingleInvoiceDetail, updateInvoice, getInvoiceEmailData } from '../../Actions/invoicesActions';
import InvoiceEmailPopup from './Popups/InvoiceEmailPopup';
import EditInvoicePopup from './Popups/EditInvoicePopup';
import DeleteAdjustmentPopup from './Popups/DeleteAdjustmentPopup';
import AddAdjustmentPopup from './Popups/AddAjustmentPopup';
import { billingTranslations } from '../../Translations/BillingTranslations'
import ReactToPrint from 'react-to-print';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom'; // removed useHistory v5
import isEmpty from '../../Utilities/isEmpty';
import { InvoicesDetailsSkeleton } from './InvoicesDeatailsSkeleton';
import Breadcrumbs from '../../Common/Breadcrumbs';
import { ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';
import { InvoiceAdjustment, InvoiceAdressPayload, NewAdjustment } from '../../Interfaces/PayloadsAndResponses/Invoice';
import { getOrderTimeZone } from '../../Utilities/getZonalTime';

export type UpdateInvoiceHandler = (updateType: 'editAddresses' | 'deleteAdjustment' | 'addAdjustment', updatedData?: NewAdjustment[] | InvoiceAdressPayload) => void;

const InvoiceDetails = () => {
  const printRef = useRef<HTMLDivElement>(null);
  const dispatch: any = useDispatch();
  const location = useLocation();
  // const history = useHistory<{ from?: string, to?: string }>();
  const navigate = useNavigate();

  const { id: invoiceId, restaurantId } = useParams<{ id: string, restaurantId: string }>();
  const [deleteAdjustment, setDeleteAdjustment] = useState<InvoiceAdjustment>();
  const [isEmailPopupOpen, setIsEmailPopupOpen] = useState<boolean>(false);
  const [isEditInvoicePopupOpen, setIsEditInvoicePopupOpen] = useState<boolean>(false);
  const [isDeleteAdjustmentPopupOpen, setIsDeleteAdjustmentPopupOpen] = useState<boolean>(false);
  const [isAddAdjustmentPopupOpen, setIsAddAdjustmentPopupOpen] = useState<boolean>(false);
  const [isUpdatingInvoice, setIsUpdatingInvoice] = useState<boolean>(false);

  const { single_invoice_detail, invoices_loading, invoice_email } = useSelector((state: ReduxRootState) => state?.invoices);

  useEffect(() => {
    billingTranslations();
    if (invoiceId && restaurantId) {
      dispatch(getSingleInvoiceDetail(invoiceId, restaurantId));
      dispatch(getInvoiceEmailData(invoiceId, restaurantId))
    }
  }, [restaurantId, invoiceId, dispatch])

  useEffect(() => {
    const billToHTML = document.getElementById('billTo'), invoiceToHTML = document.getElementById('invoiceTo')
    if (billToHTML && invoiceToHTML) {
      billToHTML.innerHTML = single_invoice_detail?.bill_to ?? '';
      invoiceToHTML.innerHTML = single_invoice_detail?.ship_to ?? '';
      billToHTML?.querySelectorAll('p')?.forEach(p => p.style.margin = '0px');
      invoiceToHTML?.querySelectorAll('p')?.forEach(p => p.style.margin = '0px');
    }
  }, [single_invoice_detail])

  const updateInvoiceHandler: UpdateInvoiceHandler = (updateType, updatedData) => {
    let dispatchData;
    let updateInvoiceCallback = () => { }
    setIsUpdatingInvoice(true);
    if (updateType === 'editAddresses' && updatedData) {
      dispatchData = updatedData as InvoiceAdressPayload;
      updateInvoiceCallback = () => { setIsEditInvoicePopupOpen(false); setIsUpdatingInvoice(false) }
    } else if (updateType === 'deleteAdjustment' && deleteAdjustment?.id) {
      dispatchData = { adjustments_attributes: [{ id: deleteAdjustment?.id, _destroy: true }] };
      updateInvoiceCallback = () => { setIsDeleteAdjustmentPopupOpen(false); setIsUpdatingInvoice(false) }
    } else if (updateType === 'addAdjustment') {
      dispatchData = { adjustments_attributes: updatedData as NewAdjustment[] };
      updateInvoiceCallback = () => { setIsAddAdjustmentPopupOpen(false); setIsUpdatingInvoice(false) }
    } else {
      return
    }
    if(dispatchData && invoiceId && restaurantId)
    dispatch(updateInvoice(dispatchData, invoiceId, restaurantId, updateInvoiceCallback));
  }

  const generatePrint = () => {
    const printRefCopy = (printRef?.current as unknown as HTMLElement)?.cloneNode(true) as HTMLElement;
    printRefCopy?.classList?.add('px-5');
    printRefCopy?.querySelectorAll('.bg-dark.text-white')
      ?.forEach((th) => th?.classList?.remove('text-white'));
    printRefCopy?.querySelectorAll('.btn-link.d-inline.text-decoration-none, .btn.btn-light.btn-sm.text-danger')
      ?.forEach((link) => { link?.remove && link.remove() });
    return printRefCopy
  }
  const goToInvoicesHandler = () => {
    // const from = history?.location?.state?.from, to = history?.location?.state?.to; // v5
    const from = location?.state?.from, to = location?.state?.to; // Get state from location

    // if (from && to) {
    //   history.push(`/dashboard/restaurant/${restaurantId}/invoices/?from=${from}&&to=${to}`)
    // } else {
    //   history.push(`/dashboard/restaurant/${restaurantId}/invoices`)
    // }
    if (from && to) {
      navigate(`/dashboard/restaurant/${restaurantId}/invoices/?from=${from}&&to=${to}`); // Use navigate instead of history.push
    } else {
      navigate(`/dashboard/restaurant/${restaurantId}/invoices`); // Use navigate instead of history.push
    }
  }

  const invoiceItems = single_invoice_detail?.line_items?.map((item, index) =>
    <tr key={index} className='text-right'>
      <td className='text-left'>{index + 1}</td>
      <td className='text-left'>{item?.item}</td>
      <td>{item?.quantity}</td>
      <td>${parseFloat(item?.unit_price)?.toFixed(2)}</td>
      <td>${parseFloat(item?.company_paid)?.toFixed(2)}</td>
      <td>${parseFloat(item?.amount)?.toFixed(2)}</td>
    </tr>)
  const invoiceAdjustments = single_invoice_detail?.adjustments?.map((adjustment, index) =>
    <tr key={index} className='text-right'>
      <td className='text-left'>{index + 1}</td>
      <td className='text-left'>{adjustment?.description}</td>
      <td className='text-left text-capitalize'>{adjustment?.adjustment_type}</td>
      <td>{adjustment?.adjustment_type === 'discount' ? '-' : '+'}${parseFloat(adjustment?.amount)?.toFixed(2)}</td>
      <td>
        <button className='btn btn-light btn-sm text-danger' onClick={() => { setDeleteAdjustment(adjustment); setIsDeleteAdjustmentPopupOpen(true) }}><i className='fas fa-minus-circle'></i></button>
      </td>
    </tr>
  )
  // const from = history?.location?.state?.from, to = history?.location?.state?.to;
  // const linkList = [
  //   { name: 'Invoices', url: (from && to) ? `/dashboard/restaurant/${restaurantId}/invoices/?from=${from}&&to=${to}` : `/dashboard/restaurant/${restaurantId}/invoices`, show: true },
  //   { name: `Invoice # ${single_invoice_detail?.id}`, show: true }
  // ]
  const from = location?.state?.from, to = location?.state?.to;
  const linkList = [
    { name: 'Invoices', url: (from && to) ? `/dashboard/restaurant/${restaurantId}/invoices/?from=${from}&&to=${to}` : `/dashboard/restaurant/${restaurantId}/invoices`, show: true },
    { name: `Invoice # ${single_invoice_detail?.id}`, show: true }
  ];
  return <React.Fragment>
    {invoices_loading ?
      <InvoicesDetailsSkeleton /> :
      <div>
        {single_invoice_detail ?
          <React.Fragment>
            <Breadcrumbs linkList={linkList}  payment={true} />
            <div className='w-100' ref={printRef}>
              <div className='container-fluid px-0'>
                <h2 className='mt-4'>
                  <span className='text-secondary cursor-pointer'>
                    <span onClick={goToInvoicesHandler}>
                      <i className='fas fa-arrow-circle-left' ></i>
                    </span>
                  </span> Invoice Details
                </h2>
                <p className='muted mb-4'>Please see full details of this invoice below.</p>
              </div>
              <div className='container-fluid d-flex justify-content-between px-0'>
                <div className='col-lg-3 pl-0'>
                  <p className='mb-2'><b>Bill To</b></p>
                  <p id='billTo' className='mb-2'></p>
                  <p className='btn-link d-inline text-decoration-none' style={{ cursor: 'pointer' }} onClick={() => setIsEditInvoicePopupOpen(true)}>Edit</p>
                </div>
                <div className='col-lg-3 pr-0'>
                  <p className='mb-2 text-right'><b>Invoice to</b></p>
                  <p id='invoiceTo' className='text-right mb-2'></p>
                  <p className='btn-link float-right d-inline text-decoration-none' style={{ cursor: 'pointer' }} onClick={() => setIsEditInvoicePopupOpen(true)}>Edit</p>
                </div>
              </div>
              <div className='container-fluid d-flex justify-content-between px-0'>
                <div className='col-lg-3 pl-0'>
                  <p className='mb-0 mt-3'>Delivery Date : {getOrderTimeZone(single_invoice_detail?.delivery_date, 'EEE. MMM dd, zzzz', single_invoice_detail?.time_zone)}</p>
                  <p>Due Date : {getOrderTimeZone(single_invoice_detail?.due_date, 'EEE. MMM dd, zzzz', single_invoice_detail?.time_zone)}</p>
                </div>
              </div>
              <div className='container-fluid d-flex justify-content-center w-100 px-0'>
                <div className='table-responsive w-100'>
                  <table className='table'>
                    <thead>
                      <tr className='bg-dark text-white'>
                        <th>#</th>
                        <th>Item Description</th>
                        <th className='text-right'>Quantity</th>
                        <th className='text-right'>Unit cost</th>
                        <th className='text-right'>Company Paid</th>
                        <th className='text-right'>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoiceItems}
                    </tbody>
                  </table>
                </div>
              </div>
              {!isEmpty(single_invoice_detail?.adjustments) && <React.Fragment>
                <h4 className='border-top p-3 mt-3'>Adjustments</h4>
                <div className='container-fluid  d-flex justify-content-center w-100'>
                  <div className='table-responsive w-100'>
                    <table className='table'>
                      <thead>
                        <tr className='bg-dark text-white'>
                          <th>#</th>
                          <th>Description</th>
                          <th className='text-left'>Type</th>
                          <th className='text-right'>Amount</th>
                          <th className=''></th>
                        </tr>
                      </thead>
                      <tbody>
                        {invoiceAdjustments}
                      </tbody>
                    </table>
                  </div>
                </div>
              </React.Fragment>}
              <div className='container-fluid mt-5 w-100'>
                <p className='text-right mb-2'>Count: {single_invoice_detail?.total_items}</p>
                <p className='text-right mb-2'>Sub-Total amount: ${parseFloat(single_invoice_detail?.sub_total)?.toFixed(2)}</p>
                <p className='text-right mb-2'>Adjustments: {single_invoice_detail?.adjustments_total < 0 && '-'}${(Math.abs(parseFloat(`${single_invoice_detail?.adjustments_total}`)))?.toFixed(2)}</p>
                <p className='text-right'>Tax ({parseFloat(single_invoice_detail?.sale_tax_percentage)?.toFixed(2)}) : ${parseFloat(single_invoice_detail?.sales_tax)?.toFixed(2)}</p>
                <h4 className='text-right mb-5'>Total Due : ${parseFloat(single_invoice_detail?.total_due)?.toFixed(2)}</h4>
                <hr />
              </div>
            </div>
            <div className='container-fluid w-100'>
              <ReactToPrint
                trigger={() => <button className='btn btn-primary float-right mt-4 ml-2 wide-btn'><i className='mdi mdi-printer mr-1'></i>Print</button>}
                content={generatePrint}
              />
              <button type='button' className='btn btn-warning float-right mt-4 ml-2 wide-btn' onClick={() => setIsAddAdjustmentPopupOpen(true)}>
                <i className='mdi mdi-table-edit  mr-1'></i>Add Adjustments
              </button>
              <button type='button' className='btn btn-success float-right mt-4 wide-btn' onClick={() => setIsEmailPopupOpen(true)}>
                <i className='mdi mdi-telegram mr-1'></i>Email Invoice
              </button>
            </div>
          </React.Fragment> : ''
        }
        {(!single_invoice_detail && !invoices_loading) ? <h3>No Invoice Found</h3> : ''}
      </div>
    }
    <InvoiceEmailPopup
      modalIsOpen={isEmailPopupOpen}
      closeModal={() => setIsEmailPopupOpen(false)}
      emailData={invoice_email}
    />
    <EditInvoicePopup
      bill_to={single_invoice_detail?.bill_to}
      invoice_to={single_invoice_detail?.ship_to}
      modalIsOpen={isEditInvoicePopupOpen}
      closeModal={() => setIsEditInvoicePopupOpen(false)}
      editInvoice={updateInvoiceHandler}
      invoiceUpdating={isUpdatingInvoice}
    />
    <DeleteAdjustmentPopup
      modalIsOpen={isDeleteAdjustmentPopupOpen}
      closeModal={() => setIsDeleteAdjustmentPopupOpen(false)}
      deleteInvoiceAdjustment={updateInvoiceHandler}
      invoiceUpdating={isUpdatingInvoice}
    />
    <AddAdjustmentPopup
      modalIsOpen={isAddAdjustmentPopupOpen}
      closeModal={() => setIsAddAdjustmentPopupOpen(false)}
      addAdjustment={updateInvoiceHandler}
      invoiceUpdating={isUpdatingInvoice}
    />
  </React.Fragment>
}

export default InvoiceDetails;



// import { Component } from 'react';
// import { connect } from 'react-redux';
// import { logoutUser } from '../../Actions/authActions';
// import { RouteComponentProps } from 'react-router-dom';

// type LogoutProps = {
//   logoutUser: () => void;
// } & RouteComponentProps

// class Logout extends Component<LogoutProps, {}> {
//   componentDidMount() {
//     this.props.logoutUser();
//     this.props.history.push('/');
//   }
//   render() {
//     return (''
//     )
//   }
// }

// export default connect(null, { logoutUser })(Logout);

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { logoutUser } from '../../Actions/authActions';
// import { useHistory } from 'react-router'; // Old method (React Router v5)
import { useNavigate } from 'react-router-dom'; // New method (React Router v6)

type LogoutProps = {
  logoutUser: () => void;
}

const Logout = ({ logoutUser }: LogoutProps) => {
  // const history = useHistory(); // Old React Router v5 hook
  const navigate = useNavigate(); // New React Router v6 hook

  useEffect(() => {
    logoutUser();
    // history.push('/'); // Old way of navigation (React Router v5)
    navigate('/'); // New way of navigation (React Router v6)
  }, [logoutUser, navigate]);

  return (<></>);
}

export default connect(null, { logoutUser })(Logout);

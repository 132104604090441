import React, { useEffect } from 'react';
// import { useHistory, useLocation } from 'react-router-dom'; // React Router v5
import { useNavigate, useLocation } from 'react-router-dom'; // React Router v6
import { useDispatch } from 'react-redux';
import { forgotPasswordConfirmation } from '../../Actions/authActions';

const ResetPasswordVerification: React.FC = () => {
  const location = useLocation();
  // const history = useHistory(); // Old React Router v5 hook
  const navigate = useNavigate(); // New React Router v6 hook
  const dispatch: any = useDispatch();
  const inviteToken = location.pathname.split('/')[3];

  useEffect(() => {
    dispatch(forgotPasswordConfirmation(inviteToken, navigate, (message) => {
      // history.push('/reset_password', { resetMessage: message }); // Old way (React Router v5)
      navigate('/reset_password', { state: { resetMessage: message } }); // New way (React Router v6)
    })
    );
    // eslint-disable-next-line
  }, [dispatch, inviteToken, navigate]);

  return (
    <div className='h-100 w-100 d-flex justify-content-center align-items-center'>
      <h3>Checking Link Validity...</h3>
    </div>
  );
};

export default ResetPasswordVerification;
